import axios from "axios"
import store from "@/store"
import { Message } from "element-ui"
import {
    getToken,
    removeToken
} from "@/utils/auth"
//console.log(process.env)
const service = axios.create({
    baseURL: process.env.NODE_ENV == "development" ? "/api" : (process.env.NODE_ENV == "preview" ? "https://api-preview-hk.wumitech.com" : "https://api-hk.wumitech.com"),
    timeout: 90000,
    withCredentials: false
})
service.interceptors.request.use(
    config => {
        //console.log(getToken())
        if (store.getters.token || store.getters.subtoken) {
            config.headers['Authorization'] = getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => {
        const res = response.data

        return res
    },
    error => {
        // Message.error("请求异常")
        //刷新token
        if(error.response.status === 401){
            removeToken()
            Message.error("登录超时，请重新登录！");

        }
        return Promise.reject(error)
    }
)
export default service