<template>
  <div class="pagemain-wrap">
    <transition name="fade-transform" mode="out-in">
      <keep-alive exclude="orderconfirm,createphone,grouplist,linelist,lineorderconfirm,upgradeorderconfirm,renewconfirm,personalcenter,channelmainaccount,channelsubaccount,recharge,invoice,moneylist,ApplyRouter,routerOrderConfirm">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>

</style>
