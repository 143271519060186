<template>
  <div class="app-wrap">
    <topbar class="topbar-wrap"></topbar>
    <sidebar class="sidebar-wrap"></sidebar>
    <page-main class="page-main"></page-main>
  </div>
</template>
<script>
import { Sidebar, PageMain, Topbar } from "./components";
export default {
  name: "layout",
  data() {
    return {};
  },
  components: {
    Sidebar,
    Topbar,
    PageMain,
  },
};
</script>
<style lang="scss" scoped>
.app-wrap {
  height: 100%;
  position: relative;
}
.page-main {
  margin-left: 227px;
  height: calc(100% - 65px);
  background: rgba(242, 243, 247, 1);
  // background: #DFE4F7;
}
.sidebar-wrap {
  width: 227px;
  height: calc(100% - 65px);
  // background: rgba(228, 229, 241, 1);
  background: #DFE4F7;
  position: absolute;
  top: 65px;
  left: 0;
  bottom: 0;
}
.topbar-wrap {
  height: 65px;
  background: linear-gradient(
    90deg,
    rgba(74, 99, 240, 0.93) 0%,
    rgba(42, 130, 228, 1) 100%
  );
}
</style>