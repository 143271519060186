import {
    login,
    wechatlogin,
    getvertification,
    getverify,
    userinfo,
    register,
    resetpassword,
    resettoken,
    getwxredirecturl,
    setinfo,
    bindaccount,
    unbindaccount,
    wechatbind,
    userwallet,
    channelmainlist,
    channelsublist,
    channelrebates,
    channelrebatesb,
    channelouterid,
    channelinviter,
    updatechannelinviter,
    transferreminder,
    notifyphone,
    accountphone,
    listrecharge,
    setchannelcompany,
    createinvoice,
    listinvoice,
    deleteinvoice,
    setdefaultinvoice,
    getinvoiceamount,
    applyinvoice,
    getlistinvoice,
    setusername,
    oldwithnewdata,
} from "@/api/user"
import {
    getToken,
    setToken,
    removeToken,
    setCookie,
    removeCookie,
    setPhoneNumber,
    removePhoneNumber
} from '@/utils/auth'
import {
    replaceStr
} from "@/utils/validate"
import {
    childlist,
    mainlist,
    child_nochannel_list,
    main_nochannel_list,
    main_nochannel_usertype_list,
    main_usertype_list,

    child_phone_list,
    main_phone_list,
    child_nochannel_phone_list,
    main_nochannel_phone_list,
    main_usertype_phone_list,
    main_nochannel_usertype_phone_list,

    main_phone_nomatrix_list,
    main_nochannel_phone_nomatrix_list,
    main_usertype_phone_nomatrix_list,
    main_nochannel_usertype_phone_nomatrix_list,
    child_phone_nomatrix_list,
    child_nochannel_phone_nomatrix_list,


   
} from "../../utils/menuList"
//import { wechatbind } from "../../api/user"
const state = {
    token: getToken(),
    gender: '',
    nickname: '',
    phonenumber: '',
    wechat: false,
    qq: false,
    userId: '',
    subAccount: '',
    menulist:[],
    authenticated: '',
    channelType: '',
    userType:'',
    parentId:'',
    showPhone:false,
    showMatrixPhone:false,

}
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_GENDER: (state, gender) => {
        state.gender = gender
    },
    SET_PHONE: (state, phonenumber) => {
        if (phonenumber) {
            const dimNumber = replaceStr({
                startPosition: 3,
                endPosition: 7,
                originStr: phonenumber,
                replaceStr: "*",
            });
            setPhoneNumber(dimNumber)
        }
        state.phonenumber = phonenumber
    },
    SET_USERNAME: (state, username) => {
        if (username) {
            setCookie('username', username)
        }
    },
    SET_NICKNAME: (state, nickname) => {
        state.nickname = nickname
    },
    SET_WECHAT: (state, wechat) => {
        state.wechat = wechat
    },
    SET_QQ: (state, qq) => {
        state.qq = qq
    },
    SET_USERID: (state, userId) => {
        state.userId = userId
        setCookie('userId', userId)
    },
    SET_PARENTID: (state, parentId) => {
        state.parentId = parentId
    },

    SET_CHANNELTYPE: (state, channeltype) => {
        state.channelType = channeltype
    },

    SET_USERTYPE: (state, userType) => {
        state.userType = userType
    },
    SHOW_MATRIXPHONE: (state, showMatrixPhone) => {
        state.showMatrixPhone = showMatrixPhone
    },
    SHOW_PHONE: (state, showPhone) => {
        state.showPhone = showPhone
    },

    SET_SUBACCOUNT: (state, subaccount) => {
        setCookie("subaccount", subaccount)
        state.subAccount = subaccount
        if(state.showPhone){
            if(state.showMatrixPhone){
                if (subaccount) {//子账号
                    if (state.channelType === 0) {
                        state.menulist = child_nochannel_phone_list
                    } else {
                        state.menulist = child_phone_list
                    }
                } else {//主账号
                    if (state.channelType === 0) {//非渠道
                        if(state.userType === 0 ){//中小型
                            state.menulist = main_nochannel_phone_list
                        }else{//代理1，大客户2都显示
                            state.menulist = main_nochannel_usertype_phone_list
                        }
                    } else {//渠道
                        if(state.userType === 0){
                            state.menulist = main_phone_list
                        }else{//不存在
                            state.menulist = main_usertype_phone_list
                        }
                    }
                }
            }else{
                if (subaccount) {//子账号
                    if (state.channelType === 0) {
                        state.menulist = child_nochannel_phone_nomatrix_list
                    } else {
                        state.menulist = child_phone_nomatrix_list
                    }
                } else {//主账号
                    if (state.channelType === 0) {//非渠道
                        if(state.userType === 0 ){//中小型
                            state.menulist = main_nochannel_phone_nomatrix_list
                        }else{//代理1，大客户2都显示
                            state.menulist = main_nochannel_usertype_phone_nomatrix_list
                        }
                    } else {//渠道
                        if(state.userType === 0){
                            state.menulist = main_phone_nomatrix_list
                        }else{//不存在
                            state.menulist = main_usertype_phone_nomatrix_list
                        }
                    }
                }
            }
           
        }else{
            if (subaccount) {//子账号
                if (state.channelType === 0) {
                    state.menulist = child_nochannel_list
                } else {
                    state.menulist = childlist
                }
            } else {//主账号
                if (state.channelType === 0) {//非渠道
                    if(state.userType === 0 ){//中小型
                        state.menulist = main_nochannel_list
                    }else{//代理1，大客户2都显示
                        state.menulist = main_nochannel_usertype_list
                    }
                } else {//渠道
                    if(state.userType === 0){
                        state.menulist = mainlist
                    }else{//不存在
                        state.menulist = main_usertype_list
                    }
                }
            }
        }
        
    },
    SET_AUTHENTICATED: (state, authenticated) => {
        setCookie("authenticated", authenticated)
        state.authenticated = authenticated
    },
}
const actions = {
    sendvertification({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getvertification({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getverifycode({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getverify({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    login({
        commit
    }, userInfo) {
        return new Promise((resolve, reject) => {
            login({
                ...userInfo
            }).then((res) => {
                if (res.RetCode == 0) {
                    setToken(res.AccessToken)
                    commit("SET_TOKEN", res.AccessToken)
                }
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    wxlogin({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            wechatlogin({
                ...data
            }).then((res) => {
                if (res.RetCode == 0) {
                    setToken(res.AccessToken)
                    commit("SET_TOKEN", res.AccessToken)
                }
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    wxbind({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            wechatbind({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getuserinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            userinfo({
                ...data
            }).then((res) => {
                commit("SET_GENDER", res.Gender)
                commit("SET_PHONE", res.PhoneNumber)
                commit("SET_USERNAME", res.Username)
                commit("SET_NICKNAME", res.NickName)
                commit("SET_WECHAT", res.Wechat)
                commit("SET_QQ", res.QQ)
                commit("SET_USERID", res.UserId)
                commit("SET_CHANNELTYPE", res.ChannelType)
                commit("SET_SUBACCOUNT", res.SubAccount)
                commit("SET_AUTHENTICATED", res.Authenticated)
                commit("SET_USERTYPE", res.UserType)
                commit("SET_PARENTID", res.ParentId)
                commit("SHOW_PHONE", res.ShowPhone)
                commit("SHOW_MATRIXPHONE", res.ShowMatrixPhone)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    outlogin({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            commit("SET_TOKEN", '')
            removeToken()
            removePhoneNumber()
            removeCookie('subaccount')
            removeCookie('authenticated')
            removeCookie('username')
            resolve()
        })
    },
    userregister({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            register({
                ...data
            }).then((res) => {
                if (res.RetCode == 0) {
                    setToken(res.AccessToken)
                    commit("SET_TOKEN", res.AccessToken)
                }
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    userresetpass({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            resetpassword({
                ...data
            }).then((res) => {
                if (res.RetCode == 0 && res.AccessToken != "") {
                    setToken(res.AccessToken)
                    commit("SET_TOKEN", res.AccessToken)
                }
                resolve(res)
            })
        })
    },
    userresettoken({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            resettoken({
                ...data
            }).then((res) => {
                if (res.RetCode == 0 && res.AccessToken != "") {
                    setToken(res.AccessToken)
                    commit("SET_TOKEN", res.AccessToken)
                }
                resolve(res)
            })
        })
    },
    loginwx({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getwxredirecturl({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    setuserinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setinfo({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    bindthirdparty({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            bindaccount({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    unbindthirdparty({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            unbindaccount({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    getuserwallet({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            userwallet({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    channelmainlist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            channelmainlist({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    channelsublist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            channelsublist({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    setchannelouterid({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            channelouterid({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    setChannelInviter({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            channelinviter({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    updateChannelInviters({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            updatechannelinviter({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    settransferreminder({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            transferreminder({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    setnotifyphone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            notifyphone({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    setaccountphone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            accountphone({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    listrecharge({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            listrecharge({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    channelrebates({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            channelrebates({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    channelrebatesb({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            channelrebatesb({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    setchannelcompany({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setchannelcompany({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    createinvoiceinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            createinvoice({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    listinvoiceinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            listinvoice({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    deleteinvoiceinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            deleteinvoice({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    setdefaultinvoiceinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setdefaultinvoice({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getinvoiceamountinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getinvoiceamount({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    applyinvoiceinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            applyinvoice({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getlistinvoiceinfo({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getlistinvoice({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    setusername({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setusername({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    oldwithnewdata({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            oldwithnewdata({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}