import requestPhone from "@/utils/requestPhone"

export function reboot(data) {
    return requestPhone({
        url: '/phone/reboot',
        method: 'post',
        data
    })
}

export function setgps(data) {
    return requestPhone({
        url: '/phone/set_gps',
        method: 'post',
        data
    })
}

export function resetphone(data) {
    return requestPhone({
        url: '/phone/reset',
        method: 'post',
        data
    })
}

export function renewphone(data) {
    return requestPhone({
        url: '/phone/renew',
        method: 'post',
        data
    })
}

export function setroot(data) {
    return requestPhone({
        url: '/phone/set_root',
        method: 'post',
        data
    })
}

export function importfile(data) {
    return requestPhone({
        url: '/phone/import_file',
        method: "post",
        data
    })
}

export function setconfig(data) {
    return requestPhone({
        url: '/phone/set_config',
        method: "post",
        data
    })
}

export function setsocks(data){
    return requestPhone({
        url:"/phone/set_socks",
        method:"post",
        data
    })
}

