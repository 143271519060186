import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from "@/layout"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'layout',
    component: Layout,
    //redirect: '/home'
    redirect: '/line/linelist'
  },
  {// testdemo
    path: '/demo',
    name:'demo',
    component: () => import("@/views/Testdemo/index.vue")
  },
  {
    path: '/home',
    name: 'home',
    component: Layout,
    redirect: '/home/home',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/Login/index.vue")
  },
  {
    path: '/sublogin',
    name: 'sublogin',
    component: () => import("@/views/Login/SubAccount/index.vue")
  },
  {
    path: '/wxlogin',
    name: 'wxlogin',
    component: () => import("@/views/Login/Account/weixin.vue")
  },
  {
    path: '/bindaccount',
    name: 'bindaccount',
    component: () => import("@/views/Login/BindAccount/index.vue")
  },
  {
    path: '/register',
    name: 'register',
    component: () => import("@/views/Register/index.vue")
  },
  {
    path: '/resetpass',
    name: 'resetpass',
    component: () => import("@/views/ResetPass/index.vue")
  },
  {
    path: '/forgetpass',
    name: 'forgetpass',
    component: () => import("@/views/ForgetPass/index.vue")
  },
  {
    path: '/user',
    name: 'user',
    component: Layout,
    children: [{
      path: 'personalcenter',
      name: 'personalcenter',
      component: () => import("@/views/PersonalCenter/index.vue")
    }, {
      path: 'resetnewpass',
      name: 'resetnewpass',
      component: () => import("@/views/ResetPass/index.vue")
    }, {
      path: 'usercenter',
      name: 'usercenter',
      component: () => import("@/views/User/index.vue")
    }, {
      path: 'subaccount',
      name: 'subaccount',
      component: () => import("@/views/SubAccount/index.vue")
    }, {
      path: 'auth',
      name: "auth",
      component: () => import("@/views/Authentication/index.vue")
    }, {
      path: 'enterprise',
      name: 'enterprise',
      component: () => import("@/views/Authentication/Enterprise/index.vue")
    }, {
      path: "enterconfirm",
      name: "enterconfirm",
      component: () => import("@/views/Authentication/EnterConfirm/index.vue")
    }, {
      path: 'recharge',
      name: 'recharge',
      component: () => import("@/views/Recharge/index.vue")
    }, {
      path: 'invoice',
      name: 'invoice',
      component: () => import("@/views/Invoice/index.vue")
    }, {
      path: 'rechargeconfirm',
      name: 'rechargeconfirm',
      component: () => import("@/views/Recharge/OrderConfirm/index.vue")
    }, {
      path: 'oldwithnew',
      name: 'oldwithnew',
      component: () => import("@/views/OldWithNew/index.vue")
    }]
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: Layout,
  //   redirect: '/home/home',
  //   children: [{
  //     path: 'home',
  //     component: () => import("@/views/Home/index.vue"),
  //     name: 'home'
  //   }]
  // },
  {
    path: '/phone',
    name: 'phone',
    component: Layout,
    children: [{
      path: 'matrixphonelist',
      name: 'matrixphonelist',
      component: () => import("@/views/MatrixPhoneList/index.vue"),
    }, {
      path: 'phonelist',
      name: 'phonelist',
      component: () => import("@/views/PhoneList/index.vue"),
    }, {
      path: 'phonecontrol',
      name: 'phonecontrol',
      component: () => import("@/views/PhoneControl/index.vue")
    }, {
      path: 'createphone',
      name: 'createphone',
      component: () => import("@/views/CreatePhone/index.vue")
    }, {
      path: 'creatematrixphone',
      name: 'creatematrixphone',
      component: () => import("@/views/CreateMatrixPhone/index.vue")
    },{
      path: 'orderconfirm',
      name: 'orderconfirm',
      component: () => import("@/views/OrderConfirm/index.vue")
    }, {
      path: 'live',
      name: 'live',
      component: () => import("@/views/Live/index.vue")
    }, {
      path: 'storage',
      name: 'storage',
      component: () => import("@/views/StorageFiles/index.vue")
    }, {
      path: 'application',
      name: 'application',
      component: () => import("@/views/Application/index.vue")
    },{
      path: 'phonemonitor',
      name: 'phonemonitor',
      component: () => import("@/views/PhoneMonitor/index.vue")
    }]
  },
  {
    path: '/ordermanagement',
    name: 'ordermanagement',
    component: Layout,
    children: [{
      path: 'index',
      name: 'index',
      component: () => import("@/views/OrderList/index.vue"),
    }, {
      path: 'renewconfirm',
      name: 'renewconfirm',
      component: () => import("@/views/RenewConfirm/index.vue")
    }]
  },
  {
    path: '/group',
    name: 'group',
    component: Layout,
    children: [{
      path: 'index',
      name: 'index',
      component: () => import("@/views/GroupList/index.vue")
    }]
  },
  {
    path: '/line',
    name: 'line',
    component: Layout,
    children: [
      {
        path: 'linelist',
        name: 'linelist',
        component: () => import("@/views/LineList/index.vue")
      },
      {
        path: 'createline',
        name: 'createline',
        component: () => import("@/views/CreateLine/index.vue")
      },
      {
        path: 'lineorderconfirm',
        name: 'lineorderconfirm',
        component: () => import("@/views/LineOrderConfirm/index.vue")
      },
      {
        path: 'upgradeorderconfirm',
        name: 'upgradeorderconfirm',
        component: () => import("@/views/UpgradeOrderConfirm/index.vue")
      },
      {
        path: 'linerenewconfirm',
        name: 'linerenewconfirm',
        component: () => import("@/views/LineRenewConfirm/index.vue")
      },
      {
        path: 'linetool',
        name: 'linetool',
        component: () => import("@/views/LineTool/index.vue")
      },
      {
        path: 'soft_router',
        name: 'soft_router',
        component: () => import('@/views/lines/Router/index.vue')
      },
      {
        path: 'router_apply',
        name: 'router_apply',
        component: () => import('@/views/lines/Router/Apply/Apply.vue')
      },
      {
        path: 'router_order_confirm',
        name: 'router_order_confirm',
        component: () => import('@/views/lines/Router/OrderConfirm/index.vue')
      },
      {
        path: 'tiktok_tool',
        name: 'tiktok_tool',
        component: () => import('@/views/lines/TiktokTool/index.vue')
      }
    ]
  },
  {
    path: '/channel',
    name: 'channel',
    component: Layout,
    children: [{
      path: 'channelmain',
      name: 'channelmain',
      component: () => import("@/views/Channel/ChannelMainAccount/index.vue")
    }, {
      path: 'channelmainresource',
      name: 'channelmainresource',
      component: () => import("@/views/Channel/ChannelMainResource/index.vue")
    }, {
      path: 'channelsub',
      name: 'channelsub',
      component: () => import("@/views/Channel/ChannelSubAccount/index.vue")
    }, {
      path: 'channelresource',
      name: 'channelresource',
      component: () => import("@/views/Channel/ChannelResource/index.vue")
    }, {
      path: 'channelrebates',
      name: 'channelrebates',
      component: () => import("@/views/Channel/ChannelRebates/index.vue")
    }]
  },
  {
    path: '/financialcenter',
    name: 'financialcenter',
    component: Layout,
    children: [{
      path: 'recharge',
      name: 'recharge',
      component: () => import("@/views/Recharge/index.vue")
    }, {
      path: 'invoice',
      name: 'invoice',
      component: () => import("@/views/Invoice/index.vue")
    }, {
      path: 'moneylist',
      name: 'moneylist',
      component: () => import("@/views/MoneyList/index.vue")
    }]
  },
  {
    path: '/personalcenter',
    name: 'personalcenter',
    component: Layout,
    children: [{
      path: 'basicinformation',
      name: 'basicinformation',
      component: () => import("@/views/PersonalCenter/index.vue")
    }]
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: Layout,
    children: [{
      path: 'applyinvoice',
      name: 'applyinvoice',
      component: () => import("@/views/ApplyInvoice/index.vue")
    }, {
      path: 'invoicedetail',
      name: 'invoicedetail',
      component: () => import("@/views/InvoiceDetail/index.vue")
    }]
  }
]

const router = new VueRouter({
  routes
})

export default router
