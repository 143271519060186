//一些常量的定义，避免多处赋值，方便维护
const wechatAppid = "wx70dd623f59efd16f";
const loginUrl = "https://console.wumitech.com/#/login";
const officalWebsite = "https://www.wumitech.com/";
const userAgreement = "https://www.wumitech.com/agreement";
const privacyPolicy = "https://www.wumitech.com/privacy";
const trainNotice = "https://wumitech.com/index/article/detail/id/22";
const liveNotice = "https://wumitech.com/index/article/detail/id/24";
const appUpload = "https://www.wumitech.com/download";
export {
    wechatAppid,
    loginUrl,
    officalWebsite,
    userAgreement,
    privacyPolicy,
    trainNotice,
    liveNotice,
    appUpload
}