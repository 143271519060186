import request from "@/utils/request"
import requestPhone from "@/utils/requestPhone";

//获取us3 token
export function getuphonefiletoken(data) {
    return requestPhone({
        url: 'phone/storage/file_upload_token',
        method: 'post',
        data
    })
}

//文件管理列表
export function describeUFilesList(data) {
    return requestPhone({
        url: 'phone/storage/files_list',
        method: 'post',
        data
    })
}

//删除文件
export function deleteuphonestoragefile(data) {
    return requestPhone({
        url: 'phone/storage/delete_file',
        method: 'post',
        data
    })
}

//获取文件推流文件地址
export function getfilesurl(data) {
    return requestPhone({
        url: 'phone/storage/file_signed_url',
        method: 'post',
        data
    })
}

//上传至us3结束
export function finishupload(data) {
    return requestPhone({
        url: 'phone/storage/notify_file_upload_finished',
        method: 'post',
        data
    })
}

//获取token
export function uploadtoken(data) {
    return requestPhone({
        url: 'phone/storage/multi_part_upload_token',
        method: 'post',
        data
    })
}