

const extraBaseRet = (data) => {
  const ret = {...data}
  if (data.hasOwnProperty('Message')) {
    ret.message = ret.Message
    ret.retCode  = ret.RetCode
    delete ret.Message
    delete ret.RetCode
  }
  return ret
}

const errorMessageStr = data => `Message: [${data.message}] \n Code: [${data.retCode}]`

export async function responseHandler(aPromise) {
  return new Promise(((resolve, reject) => {
    aPromise.then(res => {
      const data = extraBaseRet(res)
      if (data && data.retCode === 0 && !data.message) {
        resolve(data)
      } else {
        reject(errorMessageStr(data))
      }
    }).catch(reject);
  }))
}
