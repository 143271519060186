import {
    bind,
    createRouterOrder,
    list,
    modifyWiFi, reconnect,
    routerPrice, routerUpdate,
    unbind, routerOnline,
    deliveryQuery, deliverySign
} from '@/api/routers.js'

const state = {}
const mutations = {}
const actions = {
    routerPrice({commit}, data) {
        return apiResultHandler(routerPrice(data))
    },

    createRouter({commit}, data) {
        return apiResultHandler(createRouterOrder(data))
    },

    listRouters({commit}) {
        return apiResultHandler(list())
    },

    bindToLine({commit}, data) {
        return apiResultHandler(bind(data))
    },

    unbindFromLine({commit}, data) {
        return apiResultHandler(unbind(data))
    },

    wifiModify({commit}, data) {
        return apiResultHandler(modifyWiFi(data))
    },

    reconnect({commit}, data) {
        return apiResultHandler(reconnect(data))
    },

    routerUpdate({commit}, data) {
        return apiResultHandler(routerUpdate(data))
    },

    routerOnline({commit}, routerId) {
        return apiResultHandler(routerOnline(routerId))
    },

    deliveryQuery({commit}) {
        return apiResultHandler(deliveryQuery())
    },

    deliverySign({commit}, routerId) {
        return apiResultHandler(deliverySign(routerId))
    }
}

const extraBaseRet = (data) => {
    const ret = {...data}
    if (data.hasOwnProperty('Message')) {
        ret.message = ret.Message
        ret.retCode  = ret.RetCode
        delete ret.Message
        delete ret.RetCode
    }
    return ret
}

const errorMessageStr = data => `Message: [${data.message}] \n Code: [${data.retCode}]`

const apiResultHandler = async (aPromise) => {
    return new Promise(((resolve, reject) => {
        aPromise.then(res => {
            const data = extraBaseRet(res)
            if (data && data.retCode === 0 && !data.message) {
                resolve(data)
            } else {
                if (data && data.retCode === 213037) {
                    data.message = "配置路由器失败，请确认路由器网络是否联通。如操作时连接路由器的网络，可稍后刷新页面检查是否操作成功"
                }
                reject(errorMessageStr(data))
            }
        }).catch(reject);
    }))
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
