import request from "@/utils/request"

export function getvertification(data) {
    return request({
        url: '/user/send_verification_code',
        method: 'post',
        data
    })
}

export function getverify(data) {
    return request({
        url: '/user/get_verify_code',
        method: 'post',
        data
    })
}

export function login(data) {
    return request({
        url: '/user/login',
        method: 'post',
        // withCredentials: true,
        data
    })
}

export function wechatlogin(data) {
    return request({
        url: '/user/wechat_login',
        method: 'post',
        data
    })
}

export function wechatbind(data) {
    return request({
        url: '/user/wechat_bind',
        method: 'post',
        data
    })
}

export function userinfo(data) {
    return request({
        url: '/user/info',
        method: 'post',
        data
    })
}

export function userwallet(data) {
    return request({
        url: '/user/wallet',
        method: 'post',
        data
    })
}

export function resettoken(data) {
    return request({
        url: '/user/refresh_token',
        method: 'post',
        data
    })
}

export function register(data) {
    return request({
        url: '/user/register',
        method: 'post',
        // withCredentials: true,
        data
    })
}

export function resetpassword(data) {
    return request({
        url: '/user/reset_password',
        method: 'post',
        data
    })
}

export function getwxredirecturl(data) {
    return request({
       url:'/user/GetWebAppRedirectURL',
       method:'post',
       data
    })
}

export function setinfo(data) {
    return request({
       url:'/user/set',
       method:'post',
       data
    })
}

export function bindaccount(data) {
    return request({
       url:'/user/bind_third_party',
       method:'post',
       data
    })
}

export function unbindaccount(data) {
    return request({
       url:'/user/unbind_third_party',
       method:'post',
       data
    })
}
export function channelmainlist(data) {
    return request({
       url:'/user/main_channel_data',
       method:'post',
       data
    })
}
export function channelsublist(data) {
    return request({
       url:'/user/channel_data',
       method:'post',
       data
    })
}

export function channelrebates(data) {
    return request({
       url:'/user/channel_rebates',
       method:'post',
       data
    })
}

export function channelrebatesb(data) {
    return request({
       url:'/user/channel_rebates_b',
       method:'post',
       data
    })
}

export function channelouterid(data) {
    return request({
       url:'/user/set_channel_outer_id',
       method:'post',
       data
    })
}

export function channelinviter(data) {
    return request({
       url:'/user/set_channel_inviter',
       method:'post',
       data
    })
}

export function updatechannelinviter(data) {
    return request({
       url:'/user/update_channel_inviter',
       method:'post',
       data
    })
}

export function transferreminder(data) {
    return request({
        url: '/user/transfer_reminder',
        method: 'post',
        data
    })
}
export function notifyphone(data) {
    return request({
        url: '/user/set_notify_phone',
        method: 'post',
        data
    })
}
export function accountphone(data) {
    return request({
        url: '/user/set_account_phone',
        method: 'post',
        data
    })
}
export function listrecharge(data) {
    return request({
       url:'/user/list_recharge',
       method:'post',
       data
    })
}

export function setchannelcompany(data) {
    return request({
       url:'/user/set_channel_company',
       method:'post',
       data
    })
}

//创建发票信息
export function createinvoice(data) {
    return request({
        url: '/user/invoice/create_invoice_info',
        method: 'post',
        data
    })
}

//获取发票信息列表
export function listinvoice(data) {
    return request({
        url: '/user/invoice/list_invoice_info',
        method: 'post',
        data
    })
}

export function deleteinvoice(data) {
    return request({
        url: '/user/invoice/delete_invoice_info',
        method: 'post',
        data
    })
}

export function setdefaultinvoice(data) {
    return request({
        url: '/user/invoice/set_default_invoice_info',
        method: 'post',
        data
    })
}

export function getinvoiceamount(data) {
    return request({
        url: '/user/invoice/invoice_amount',
        method: 'post',
        data
    })
}

export function applyinvoice(data) {
    return request({
        url: '/user/invoice/apply_invoice',
        method: 'post',
        data
    })
}

export function getlistinvoice(data) {
    return request({
        url: '/user/invoice/list_invoice',
        method: 'post',
        data
    })
}

export function setusername(data) {
    return request({
        url: '/user/set_username',
        method: 'post',
        data
    })
}
export function oldwithnewdata(data) {
    return request({
       url:'/user/old_with_new_data',
       method:'post',
       data
    })
}
