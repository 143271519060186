import {
    setgps,
    resetphone,
    renewphone,
    setroot,
    importfile,
    setconfig,
    setsocks
} from "@/api/control"
const state = {}
const mutations = {

}
const actions = {
    setphonegps({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setgps({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    phonereset({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            resetphone({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    phonerenew({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            renewphone({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    phoneroot({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setroot({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    importfiles({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            importfile({
                ...data
            }).then((res => {
                resolve(res)
            })).catch(err => {
                reject(res)
            })
        })
    },
    phonesetconfig({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setconfig({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(res)
            })
        })
    },
    phonesetsocks({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setsocks({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(res)
            })
        })
    }

}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}