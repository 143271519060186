import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/iconfont/iconfont.css"
import "@/styles/index.scss" // global
import "./permission" 
import "./utils/directives"
import JsonExcel from 'vue-json-excel'
import * as echarts from 'echarts'
Vue.component('downloadExcel', JsonExcel)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueClipboard)
Vue.prototype.$echarts = echarts


new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
