<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" mode="vertical">
        <side-item
          v-for="item in menulist"
          :key="item.index"
          :item="item"
        ></side-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import SideItem from "./SideItem";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      activePath: "",
    };
  },
  components: {
    SideItem,
  },
  computed: {
    ...mapGetters([
      'menulist',
    ]),
    activeMenu() {
      const route = this.$route;
      const path = this.$route.path;
      return path;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
</style>