import {
    certification,
    businessLicenseOcr
} from "@/api/personal"
const state = {}
const mutations = {}
const actions = {
    submitcertification({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            certification({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    businessLicenseOcr({commit}, data) {
        return new Promise((res, rej) => {
            businessLicenseOcr(data).then(res).catch(rej)
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}