import request from "@/utils/request"
import requestPhone from "@/utils/requestPhone"


export function grouplist(data) {
    return request({
        url: '/group/list',
        method: 'post',
        data
    })
}

export function creategroup(data) {
    return request({
        url: '/group/create',
        method: "post",
        data
    })
}

export function deletegroup(data) {
    return request({
        url: '/group/delete',
        method: 'post',
        data
    })
}

export function modifygroup(data) {
    return request({
        url: '/group/modify',
        method: 'post',
        data
    })
}

export function phonelist(data) {
    return requestPhone({
        url: "/phone/list",
        method: 'post',
        data
    })
}

export function changegroup(data) {
    return requestPhone({
        url: '/phone/change_group',
        method: 'post',
        data
    })
}
export function changegroupmatrix(data) {
    return requestPhone({
        url: '/phone/change_group_matrix',
        method: 'post',
        data
    })
}



export function setname(data) {
    return requestPhone({
        url: '/phone/set_name',
        method: 'post',
        data
    })
}

export function setmatrixname(data) {
    return requestPhone({
        url: '/phone/update_matrix_name',
        method: 'post',
        data
    })
}

export function reboot(data) {
    return requestPhone({
        url: '/phone/reboot',
        method: 'post',
        data
    })
}

export function setautorenew(data) {
    return requestPhone({
        url: '/phone/auto_renew',
        method: 'post',
        data
    })
}

export function setautorenewmatrix(data) {
    return requestPhone({
        url: '/phone/auto_renew_matrix',
        method: 'post',
        data
    })
}

export function phoneregionstock(data) {
    return requestPhone({
        url: "/phone/region_stock",
        method: 'post',
        data
    })
}

export function poweron(data) {
    return requestPhone({
        url: '/phone/power_on',
        method: 'post',
        data
    })
}

export function poweroff(data) {
    return requestPhone({
        url: '/phone/power_off',
        method: 'post',
        data
    })
}

export function matrixworkgroupid(data) {
    return requestPhone({
        url: '/phone/get_matrix_work_group_id',
        method: 'post',
        data
    })
}

export function matrixgrouplist(data) {
    return requestPhone({
        url: '/phone/matrix_group_list',
        method: 'post',
        data
    })
}

export function usermatrixgrouplist(data) {
    return requestPhone({
        url: '/phone/get_user_matrix_group_list',
        method: 'post',
        data
    })
}


export function getphoneidsfororderid(data) {
    return requestPhone({
        url: '/phone/get_phoneids_for_orderid',
        method: 'post',
        data
    })
}