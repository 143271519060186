<template>
  <div class="wrap">
    <div class="title">
      <img class="logoimg" src="../../../assets/logo.png" />
      <span class="titlespan">{{bannerText}}</span>
    </div>
    <div class="top-right">
      <div class="xz" v-if="showWumiInfo"><span @click="navigate">官网</span></div>
      <div class="xz" v-if="showWumiInfo"><span @click="showdownloadmodel">APP下载</span></div>
      <!--<div class="bz"><span>帮助</span></div>
      <div class="xiaoxi">
        <i class="iconfont icon-tongzhi"></i>
      </div>-->
      <geren />
      <download v-if="showWumiInfo"
        title="客户端下载"
        :isdownloadshow="isdownloadshow"
        @closedownloadmodel="closedownloadmodel"
      />
    </div>
  </div>
</template>
<script>
import geren from "./components/geren.vue";
import download from "./components/Download.vue";
import { appUpload } from "@/utils/public";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      isdownloadshow: false,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    bannerText() {
      return (this.showWumiInfo ? '无觅' : '') + '控制台'
    },
    showWumiInfo() {
      return this.userId !== 'user-DiGJLa'
    },
  },
  components: { geren, download },
  methods: {
    showdownloadmodel() {
      //this.isdownloadshow = !this.isdownloadshow;
      window.open(appUpload);

    },
    navigate() {
       window.open("https://www.wumitech.com/")
    },
    closedownloadmodel() {
      this.isdownloadshow = !this.isdownloadshow;
    },
  },
};
</script>
<style scoped  lang="scss" >
.wrap {
  display: flex;
  justify-content: space-between;

  .title {
    width: 227px;
    text-align: center;
    font-size: 24px;
    color: rgba($color: #ffffff, $alpha: 1);
    font-weight: 700;
    line-height: 68px;
    .logoimg {
      width: 50px;
      height: 50px;
      vertical-align: middle;
      margin-right: 10px;
    }
    .titlespan {
      vertical-align: middle;
    }
  }
  .top-right {
    margin-right: 30px;
    width: 225px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .bz {
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
    }
    .xz {
      font-size: 18px;
      color: #fff;
      cursor: pointer;
    }
    .xiaoxi {
      color: #ffffff;
      cursor: pointer;
      .icon-notice {
        font-size: 25px;
      }
    }
  }
}
</style>
