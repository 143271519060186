<template>
  <el-dialog
    class="isdownload"
    :visible.sync="isdownloadshow"
    @open="open"
    :before-close="closemodel"
  >
    <div class="downloadbody">
      <el-row :gutter="0">
        <el-col :span="6">
          <el-card class="card">
            <p class="cardtitle">
              <i class="el-icon-mobile-phone cardtitleico"></i
              ><span>安卓客户端</span>
            </p>
            <img class="card-img" src="../../../../assets/android.png" />
            <!--<p class="tip-text">
              请使用<span class="stand-text">手机浏览器或支付宝</span>扫码下载
            </p>-->
            <div class="card-text">
              <el-button
                id="androidbtn"
                @click="downloadurl('android')"
                class="android-btn"
                type="primary"
                size="mini"
                >点击下载安卓客户端</el-button
              >
            </div>
          </el-card>
        </el-col>
        <!--<el-col :span="6">
          <el-card class="card">
            <p class="cardtitle">
              <i class="el-icon-mobile-phone cardtitleico"></i><span>iOS</span>
            </p>
            <img class="card-img" src="../../../../assets/android.png" />
            <div class="card-text">
              <el-button
                @click="downloadurl('ios')"
                class="ios-btn"
                type="primary"
                size="mini"
                >iOS用户请扫上方二维码</el-button
              >
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="card">
            <p class="cardtitle">
              <i class="el-icon-s-platform cardtitleico"></i
              ><span>Windows</span>
            </p>
            <img
              class="card-img"
              style="margin-top: 15px"
              src="../../../../assets/android.png"
            />
            <div class="card-text">
              <el-button
                @click="downloadurl('windows')"
                class="windows-btn"
                type="primary"
                size="mini"
                >Windows客户端下载</el-button
              >
              <p class="win-text">MAC用户请用Chrome浏览器</p>
            </div>
          </el-card>
        </el-col>-->
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: ["isdownloadshow", "title"],
  data() {
    return {};
  },
  methods: {
    open() {},
    closemodel() {
      this.$emit("closedownloadmodel");
    },
    downloadurl(type) {
      let url;
      if (type == "android") {
        url =
          "https://app-download.cn-wlcb.ufileos.com/android/WumiCloudPhone.apk";
      }
      if (type == "windows") {
        url =
          "http://uphone-client.cn-wlcb.ufileos.com/install/windows/uphone/UPhoneClientSetup.exe";
      }
      if (type == "ios") {
        return;
      }
      if (type == "withoutlogo") {
        url =
          "http://uphone-client.cn-wlcb.ufileos.com/install/android/direct_connect/ConnectClient_0.0.1.apk";
      }
      var form = document.createElement("form");
      document.getElementById("androidbtn").appendChild(form);
      form.action = url;
      form.submit();
      //   window.location.href="http://uphone-client.cn-wlcb.ufileos.com/install/android/2b_uphone/UPhoneClient_0.1.4.apk"
    },
  },
};
</script>
<style scoped lang="scss">
.isdownload {
  ::v-deep .el-dialog {
    width: 340px;
    background: rgba(88, 151, 242, 1);
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    margin-top: 0px;
    color: #ff0000;
  }
  ::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
    color: #ff0000;
  }
  ::v-deep .el-dialog__body {
    height: 420px;
    padding: 0 20px;
    overflow: hidden;
  }
  ::v-deep .el-row {
    margin: 0;
    margin-top: 20px;
  }
  .card {
    width: 300px;
    height: 380px;
    overflow: hidden;
    .cardtitle {
      text-align: center;
      height: 30px;
      font-size: 18px;
      font-weight: 800;
      //margin-top: 10px;
      .cardtitleico {
        color: rgb(56, 96, 244);
        margin-right: 5px;
      }
    }
    .card-img {
      width: 100%;
    }
    .tip-text {
      font-size: 12px;
      color: rgb(151, 151, 151);
      white-space: nowrap;
      margin-left: 30px;
    }
    .stand-text {
      font-size: 12px;
      color: rgb(255, 0, 0);
      white-space: nowrap;
    }
    .card-text {
      width: 100%;
      height: 50px;
      text-align: center;
      .ios-btn {
        margin-top: 17px;
        margin-left: -12px;
        background: linear-gradient(
          135deg,
          rgb(100, 136, 252) 0%,
          rgb(56, 96, 244) 100%
        );
        box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
          rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
          rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
      }
      .android-btn {
        margin-top: 10px;
        background: rgba(88, 151, 242, 1);
        // box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
        //   rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
        //   rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
      }
      .windows-btn {
        margin-top: 35px;
        margin-left: -2px;
        background: linear-gradient(
          135deg,
          rgb(100, 136, 252) 0%,
          rgb(56, 96, 244) 100%
        );
        box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
          rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
          rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
      }
      .win-text {
        font-size: 12px;
        color: rgb(155, 151, 151);
        margin-top: 10px;
      }
    }
  }
}
</style>