<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="announce.title"
    :visible.sync="annShow"
    width="40%"
  >
    <div class="content">
      <div class="abstract">{{ announce.abstract }}</div>
      <div class="detail" v-html="announce.detail"></div>
    </div>
    <template slot="footer">
      <el-button type="primary" :disabled="keepReading" @click="gotIt"
        >{{ tickSec ? tickSec + "s  " : "" }} 知道了</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { announcePeak } from "@/api/misc.js";
import { responseHandler } from "@/utils/response.js";
import {getToken} from "@/utils/auth";

const KEEP_SEC = 5;
const VERSION_KEY = "ann_ver";
export default {
  name: "Announcement",
  data() {
    return {
      annShow: false,
      tickSec: KEEP_SEC,
      keepReading: true,
      announce: {
        title: "",
        abstract: "",
        detail: "",
        version: "",
      },
    };
  },
  methods: {
    gotIt() {
      localStorage.setItem(VERSION_KEY, this.announce.version);
      this.annShow = false;
    },
    countDown() {
      const interval = setInterval(() => {
        this.tickSec--;
      }, 1000);
      setTimeout(() => {
        this.keepReading = false;
        clearInterval(interval);
      }, KEEP_SEC * 1000);
    },
  },
  mounted() {
    var hasToken = getToken();
    if (hasToken !== "undefined" && hasToken != null && hasToken !== "") {
      //判断是否登录
      const lastVersion = (localStorage.getItem(VERSION_KEY) || 0) - 0;
      responseHandler(announcePeak())
        .then((res) => {
          this.announce = res.announce;
          if (this.announce.version > lastVersion) {
            this.annShow = true;
            this.countDown();
          }
        })
        .catch((e) => {
          // slience
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  .abstract {
    font-size: 15px;
    font-weight: bold;
    opacity: 0.7;
  }

  .detail {
    padding: 10px;
    border-left: 3px solid lightgreen;
    margin: 20px 0 0 20px;
  }
}
</style>
