import router from "./router";
import store from "./store";
import {
    getToken
} from "@/utils/auth"
import {
    wechatAppid
} from "@/utils/public"

const whiteList = ['/login', '/register', '/resetpass', '/forgetpass', '/wxlogin', '/bindaccount', '/sublogin', '/useragreement', '/demo']


router.beforeEach(async (to, from, next) => {
    const hasToken = getToken()
    //console.log("route to path: " + to.path + " token: " + hasToken);
    if (hasToken != undefined && hasToken != "") {
        if (to.path === "/login") {
            next({
                path: '/',
            })
        } else if (to.path === "/user/personalcenter") {
            if (to.query.code) {
                let code = to.query.code
                let data = {
                    WechatCode: code,
                    AppId: wechatAppid
                }
                store.dispatch("user/wxbind", {
                    ...data
                }).then((res) => {
                    if (res.RetCode == 0) {
                        next({
                            name: 'personalcenter'
                        })
                        return
                    }
                })
            }
            next()
        } else if (to.path === "/phone/phonelist") {
            store.dispatch("user/getuserinfo")
            next()
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            if (to.query.code) {
                let code = to.query.code
                let data = {
                    WechatCode: code,
                    AppId: wechatAppid
                }
                store.dispatch("user/wxlogin", {
                    ...data
                }).then((res) => {
                    console.log("wxlogin response: " + JSON.stringify(res));
                    if (res.RetCode == 0) {
                        //next('/')
                        next({
                            path: '/',
                            query: {
                                reminder: true
                            }
                        })
                    }
                    if (res.RetCode == 16) {
                        next({
                            name: 'bindaccount',
                            params: {
                                WechatUnionID: res.WechatUnionID
                            }
                        })
                    }
                })
                return
            }
            next()
        } else {
            next(`/login?redirect=${to.path}`)
        }
    }
})

router.onError((error) => {
    console.log('routerErr:', error)
    const pattern = /Loading chunk (.)+ failed/g
    const flag = error.message.includes('Failed to fetch dynamically imported module')
    const isChunkLoadFailed = error.message.match(pattern)
    if (isChunkLoadFailed || flag) { location.reload() }
  
})

