const softRouterRoute = {
    index: '/line/soft_router',
    key: 'soft_router',
    name: '路由器管理',
    path: '/line/soft_router',
}

const tiktokTool = {
    index: '/line/tiktok_tool',
    key: 'tiktok_tool',
    name: 'TK工具',
    path: '/line/tiktok_tool',
}
const mainlist = [
    // {
    //     index: '/phone',
    //     key: 'yunshouji',
    //     name: '云手机',
    //     icon: 'iconfont icon-shouji',
    //     children: [{
    //         index: '/phone/phonelist',
    //         key: 'ysjliebiao',
    //         name: '云手机列表',
    //         path: '/phone/phonelist',
    //     }, {
    //         index: '/phone/phonecontrol',
    //         key: 'ysjqk',
    //         name: '云手机操作',
    //         path: '/phone/phonecontrol'
    //     },
    //     // {
    //     //     index: '/phone/live',
    //     //     key: 'ysjzb',
    //     //     name: "云手机直播配置",
    //     //     path: '/phone/live'
    //     // },

    //     ]
    // },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        }
        ]
    },
    {
        index: '/channel',
        key: 'qdgl',
        name: "渠道管理",
        icon: 'iconfont icon-yewuzu',
        children: [{
            index: '/channel/channelmain',
            key: 'qdhz',
            name: "所有账号客户汇总",
            path: '/channel/channelmain',
        },{
            index: '/channel/channelmainresource',
            key: 'qdzyhz',
            name: "所有账号客户资源汇总",
            path: '/channel/channelmainresource',
        },
        {
            index: '/channel/channelsub',
            key: 'qdsj',
            name: "本账号客户汇总",
            path: '/channel/channelsub',
        },
        {
            index: '/channel/channelresource',
            key: 'qdzy',
            name: "本账号客户资源汇总",
            path: '/channel/channelresource',
        },
        {
            index: '/channel/channelrebates',
            key: 'qdzy',
            name: "返利数据汇总",
            path: '/channel/channelrebates',
        }
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }]
    }
]

const main_nochannel_list = [
    // {
    //     index: '/phone',
    //     key: 'yunshouji',
    //     name: '云手机',
    //     icon: 'iconfont icon-shouji',
    //     children: [{
    //         index: '/phone/phonelist',
    //         key: 'ysjliebiao',
    //         name: '云手机列表',
    //         path: '/phone/phonelist',
    //     }, {
    //         index: '/phone/phonecontrol',
    //         key: 'ysjqk',
    //         name: '云手机操作',
    //         path: '/phone/phonecontrol'
    //     },
    //     // {
    //     //     index: '/phone/live',
    //     //     key: 'ysjzb',
    //     //     name: "云手机直播配置",
    //     //     path: '/phone/live'
    //     // },
    //     ]
    // },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        },
        {
            index: '/user/oldwithnew',
            key: 'oldwithnew',
            name: "老推新返利",
            path: '/user/oldwithnew',
        },
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }

        ]
    }
]

const main_usertype_list = [
    // {
    //     index: '/phone',
    //     key: 'yunshouji',
    //     name: '云手机',
    //     icon: 'iconfont icon-shouji',
    //     children: [{
    //         index: '/phone/phonelist',
    //         key: 'ysjliebiao',
    //         name: '云手机列表',
    //         path: '/phone/phonelist',
    //     }, {
    //         index: '/phone/phonecontrol',
    //         key: 'ysjqk',
    //         name: '云手机操作',
    //         path: '/phone/phonecontrol'
    //     },
    //     // {
    //     //     index: '/phone/live',
    //     //     key: 'ysjzb',
    //     //     name: "云手机直播配置",
    //     //     path: '/phone/live'
    //     // },

    //     ]
    // },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        }
        ]
    },
    {
        index: '/channel',
        key: 'qdgl',
        name: "渠道管理",
        icon: 'iconfont icon-yewuzu',
        children: [{
            index: '/channel/channelmain',
            key: 'qdhz',
            name: "所有账号客户汇总",
            path: '/channel/channelmain',
        },{
            index: '/channel/channelmainresource',
            key: 'qdzyhz',
            name: "所有账号客户资源汇总",
            path: '/channel/channelmainresource',
        },
        {
            index: '/channel/channelsub',
            key: 'qdsj',
            name: "本账号客户汇总",
            path: '/channel/channelsub',
        },
        {
            index: '/channel/channelresource',
            key: 'qdzy',
            name: "本账号客户资源汇总",
            path: '/channel/channelresource',
        },
        {
            index: '/channel/channelrebates',
            key: 'qdzy',
            name: "返利数据汇总",
            path: '/channel/channelrebates',
        }
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }]
    }
]

const main_nochannel_usertype_list = [
    // {
    //     index: '/phone',
    //     key: 'yunshouji',
    //     name: '云手机',
    //     icon: 'iconfont icon-shouji',
    //     children: [{
    //         index: '/phone/phonelist',
    //         key: 'ysjliebiao',
    //         name: '云手机列表',
    //         path: '/phone/phonelist',
    //     }, {
    //         index: '/phone/phonecontrol',
    //         key: 'ysjqk',
    //         name: '云手机操作',
    //         path: '/phone/phonecontrol'
    //     },
    //     // {
    //     //     index: '/phone/live',
    //     //     key: 'ysjzb',
    //     //     name: "云手机直播配置",
    //     //     path: '/phone/live'
    //     // },
    //     ]
    // },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        },
        {
            index: '/user/oldwithnew',
            key: 'oldwithnew',
            name: "老推新返利",
            path: '/user/oldwithnew',
        },
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }

        ]
    }
]

const childlist = [
//     {
//     index: '/phone',
//     key: 'yunshouji',
//     name: '云手机',
//     icon: 'iconfont icon-shouji',
//     children: [{
//         index: '/phone/phonelist',
//         key: 'ysjliebiao',
//         name: '云手机列表',
//         path: '/phone/phonelist',
//     }, {
//         index: '/phone/phonecontrol',
//         key: 'ysjqk',
//         name: '云手机操作',
//         path: '/phone/phonecontrol'
//     },
//     //  {
//     //     index: '/phone/live',
//     //     key: 'ysjzb',
//     //     name: "云手机直播配置",
//     //     path: '/phone/live'
//     // },
// ]
// },
{
    index: '/line',
    key: 'livefast',
    name: '直播线路',
    icon: 'iconfont icon-jiasu',
    children: [
        {
            index: '/line/linelist',
            key: 'xlliebiao',
            name: '线路管理',
            path: '/line/linelist',
        },
        softRouterRoute,
        tiktokTool
    ]
},
{
    index: '/channelmanagement',
    key: 'qdgl',
    name: "渠道管理",
    icon: 'iconfont icon-yewuzu',
    children: [{
        index: '/channel/channelmain',
        key: 'qdhz',
        name: "所有账号客户汇总",
        path: '/channel/channelmain',
    },{
        index: '/channel/channelmainresource',
        key: 'qdzyhz',
        name: "所有账号客户资源汇总",
        path: '/channel/channelmainresource',
    },
    {
        index: '/channel/channelsub',
        key: 'qdsj',
        name: "本账号客户汇总",
        path: '/channel/channelsub',
    },
    {
        index: '/channel/channelresource',
        key: 'qdzy',
        name: "本账号客户资源汇总",
        path: '/channel/channelresource',
    },
    {
        index: '/channel/channelrebates',
        key: 'qdzy',
        name: "返利数据汇总",
        path: '/channel/channelrebates',
    }

    ]
},
{
    index: '/personalcenter',
    key: 'qyzx',
    name: "个人中心",
    icon: 'iconfont icon-gerenzhongxin',
    children: [{
        index: '/personalcenter/basicinformation',
        key: 'jbxx',
        name: "基本信息",
        path: '/personalcenter/basicinformation',
    }
    ]
}]

const child_nochannel_list = [
//     {
//     index: '/phone',
//     key: 'yunshouji',
//     name: '云手机',
//     icon: 'iconfont icon-shouji',
//     children: [{
//         index: '/phone/phonelist',
//         key: 'ysjliebiao',
//         name: '云手机列表',
//         path: '/phone/phonelist',
//     }, {
//         index: '/phone/phonecontrol',
//         key: 'ysjqk',
//         name: '云手机操作',
//         path: '/phone/phonecontrol'
//     },
//     //  {
//     //     index: '/phone/live',
//     //     key: 'ysjzb',
//     //     name: "云手机直播配置",
//     //     path: '/phone/live'
//     // },
// ]
// },
{
    index: '/line',
    key: 'livefast',
    name: '直播线路',
    icon: 'iconfont icon-jiasu',
    children: [
        {
            index: '/line/linelist',
            key: 'xlliebiao',
            name: '线路管理',
            path: '/line/linelist',
        },
        softRouterRoute,
        tiktokTool
    ]
},
{
    index: '/personalcenter',
    key: 'qyzx',
    name: "个人中心",
    icon: 'iconfont icon-gerenzhongxin',
    children: [{
        index: '/personalcenter/basicinformation',
        key: 'jbxx',
        name: "基本信息",
        path: '/personalcenter/basicinformation',
    }
    ]
},]

const main_phone_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        },{
            index: '/phone/matrixphonelist',
            key: 'jzysjliebiao',
            name: '矩阵云手机列表',
            path: '/phone/matrixphonelist',
        },  {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },

        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        }
        ]
    },
    {
        index: '/channel',
        key: 'qdgl',
        name: "渠道管理",
        icon: 'iconfont icon-yewuzu',
        children: [{
            index: '/channel/channelmain',
            key: 'qdhz',
            name: "所有账号客户汇总",
            path: '/channel/channelmain',
        },{
            index: '/channel/channelmainresource',
            key: 'qdzyhz',
            name: "所有账号客户资源汇总",
            path: '/channel/channelmainresource',
        },
        {
            index: '/channel/channelsub',
            key: 'qdsj',
            name: "本账号客户汇总",
            path: '/channel/channelsub',
        },
        {
            index: '/channel/channelresource',
            key: 'qdzy',
            name: "本账号客户资源汇总",
            path: '/channel/channelresource',
        },
        {
            index: '/channel/channelrebates',
            key: 'qdzy',
            name: "返利数据汇总",
            path: '/channel/channelrebates',
        }
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }]
    }
]

const main_phone_nomatrix_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        // },{
        //     index: '/phone/matrixphonelist',
        //     key: 'jzysjliebiao',
        //     name: '矩阵云手机列表',
        //     path: '/phone/matrixphonelist',
        },  {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },

        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        }
        ]
    },
    {
        index: '/channel',
        key: 'qdgl',
        name: "渠道管理",
        icon: 'iconfont icon-yewuzu',
        children: [{
            index: '/channel/channelmain',
            key: 'qdhz',
            name: "所有账号客户汇总",
            path: '/channel/channelmain',
        },{
            index: '/channel/channelmainresource',
            key: 'qdzyhz',
            name: "所有账号客户资源汇总",
            path: '/channel/channelmainresource',
        },
        {
            index: '/channel/channelsub',
            key: 'qdsj',
            name: "本账号客户汇总",
            path: '/channel/channelsub',
        },
        {
            index: '/channel/channelresource',
            key: 'qdzy',
            name: "本账号客户资源汇总",
            path: '/channel/channelresource',
        },
        {
            index: '/channel/channelrebates',
            key: 'qdzy',
            name: "返利数据汇总",
            path: '/channel/channelrebates',
        }
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }]
    }
]

const main_nochannel_phone_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        }, {
            index: '/phone/matrixphonelist',
            key: 'jzysjliebiao',
            name: '矩阵云手机列表',
            path: '/phone/matrixphonelist',
        }, {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },
        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        },
        {
            index: '/user/oldwithnew',
            key: 'oldwithnew',
            name: "老推新返利",
            path: '/user/oldwithnew',
        },
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }

        ]
    }
]

const main_nochannel_phone_nomatrix_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        // }, {
        //     index: '/phone/matrixphonelist',
        //     key: 'jzysjliebiao',
        //     name: '矩阵云手机列表',
        //     path: '/phone/matrixphonelist',
        }, {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },
        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        },
        {
            index: '/user/oldwithnew',
            key: 'oldwithnew',
            name: "老推新返利",
            path: '/user/oldwithnew',
        },
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }

        ]
    }
]

const main_usertype_phone_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        },{
            index: '/phone/matrixphonelist',
            key: 'jzysjliebiao',
            name: '矩阵云手机列表',
            path: '/phone/matrixphonelist',
        },  {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },

        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        }
        ]
    },
    {
        index: '/channel',
        key: 'qdgl',
        name: "渠道管理",
        icon: 'iconfont icon-yewuzu',
        children: [{
            index: '/channel/channelmain',
            key: 'qdhz',
            name: "所有账号客户汇总",
            path: '/channel/channelmain',
        },{
            index: '/channel/channelmainresource',
            key: 'qdzyhz',
            name: "所有账号客户资源汇总",
            path: '/channel/channelmainresource',
        },
        {
            index: '/channel/channelsub',
            key: 'qdsj',
            name: "本账号客户汇总",
            path: '/channel/channelsub',
        },
        {
            index: '/channel/channelresource',
            key: 'qdzy',
            name: "本账号客户资源汇总",
            path: '/channel/channelresource',
        },
        {
            index: '/channel/channelrebates',
            key: 'qdzy',
            name: "返利数据汇总",
            path: '/channel/channelrebates',
        }
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }]
    }
]

const main_usertype_phone_nomatrix_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        // },{
        //     index: '/phone/matrixphonelist',
        //     key: 'jzysjliebiao',
        //     name: '矩阵云手机列表',
        //     path: '/phone/matrixphonelist',
        },  {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },

        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        }
        ]
    },
    {
        index: '/channel',
        key: 'qdgl',
        name: "渠道管理",
        icon: 'iconfont icon-yewuzu',
        children: [{
            index: '/channel/channelmain',
            key: 'qdhz',
            name: "所有账号客户汇总",
            path: '/channel/channelmain',
        },{
            index: '/channel/channelmainresource',
            key: 'qdzyhz',
            name: "所有账号客户资源汇总",
            path: '/channel/channelmainresource',
        },
        {
            index: '/channel/channelsub',
            key: 'qdsj',
            name: "本账号客户汇总",
            path: '/channel/channelsub',
        },
        {
            index: '/channel/channelresource',
            key: 'qdzy',
            name: "本账号客户资源汇总",
            path: '/channel/channelresource',
        },
        {
            index: '/channel/channelrebates',
            key: 'qdzy',
            name: "返利数据汇总",
            path: '/channel/channelrebates',
        }
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }]
    }
]

const main_nochannel_usertype_phone_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        }, {
            index: '/phone/matrixphonelist',
            key: 'jzysjliebiao',
            name: '矩阵云手机列表',
            path: '/phone/matrixphonelist',
        }, {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },
        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        },
        {
            index: '/user/oldwithnew',
            key: 'oldwithnew',
            name: "老推新返利",
            path: '/user/oldwithnew',
        },
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }

        ]
    }
]

const main_nochannel_usertype_phone_nomatrix_list = [
    {
        index: '/phone',
        key: 'yunshouji',
        name: '云手机',
        icon: 'iconfont icon-shouji',
        children: [{
            index: '/phone/phonelist',
            key: 'ysjliebiao',
            name: '通用云手机列表',
            path: '/phone/phonelist',
        // }, {
        //     index: '/phone/matrixphonelist',
        //     key: 'jzysjliebiao',
        //     name: '矩阵云手机列表',
        //     path: '/phone/matrixphonelist',
        }, {
            index: '/phone/phonecontrol',
            key: 'ysjqk',
            name: '云手机操作',
            path: '/phone/phonecontrol'
        },
        // {
        //     index: '/phone/live',
        //     key: 'ysjzb',
        //     name: "云手机直播配置",
        //     path: '/phone/live'
        // },
        ]
    },
    {
        index: '/line',
        key: 'livefast',
        name: '直播线路',
        imgurl: require('../assets/zbjs.png'),
        children: [
            {
                index: '/line/linelist',
                key: 'xlliebiao',
                name: '线路管理',
                path: '/line/linelist',
            },
            softRouterRoute,
            {
                index: '/line/linetool',
                key: 'xlzd',
                name: '线路诊断',
                path: '/line/linetool',
            },
            tiktokTool
        ]
    },
    {
        index: '/ordermanagement',
        key: 'cwzx',
        name: "管理中心",
        icon: 'iconfont icon-guanlizhongxin-shezhi-12',
        children: [{
            index: '/personalcenter/basicinformation',
            key: 'qy',
            name: "基本信息管理",
            path: '/personalcenter/basicinformation',
        },
        {
            index: '/group/index',
            key: 'fzgl',
            name: '分组管理',
            path: '/group/index',
        },
        {
            index: '/user/subaccount',
            key: 'zzh',
            name: "子账号管理",
            path: '/user/subaccount',
        },
        {
            index: '/user/oldwithnew',
            key: 'oldwithnew',
            name: "老推新返利",
            path: '/user/oldwithnew',
        },
        ]
    },
    {
        index: '/financialcenter',
        key: 'cwzx',
        name: "财务中心",
        icon: 'iconfont icon-jiaofeizhangdan',
        children: [{
            index: '/financialcenter/recharge',
            key: 'zhcz',
            name: "账户充值",
            path: '/financialcenter/recharge',
        }, {
            index: '/ordermanagement/index',
            key: 'dingdan',
            name: "订单管理",
            path: '/ordermanagement/index',
        }, {
            index: '/financialcenter/moneylist',
            key: 'szmx',
            name: "充值管理",
            path: '/financialcenter/moneylist',
        }, {
            index: '/financialcenter/invoice',
            key: 'fpgl',
            name: "发票管理",
            path: '/financialcenter/invoice',
        }

        ]
    }
]

const child_phone_list = [
    {
    index: '/phone',
    key: 'yunshouji',
    name: '云手机',
    icon: 'iconfont icon-shouji',
    children: [{
        index: '/phone/phonelist',
        key: 'ysjliebiao',
        name: '通用云手机列表',
        path: '/phone/phonelist',
    },{
        index: '/phone/matrixphonelist',
        key: 'jzysjliebiao',
        name: '矩阵云手机列表',
        path: '/phone/matrixphonelist',
    },  {
        index: '/phone/phonecontrol',
        key: 'ysjqk',
        name: '云手机操作',
        path: '/phone/phonecontrol'
    },
    //  {
    //     index: '/phone/live',
    //     key: 'ysjzb',
    //     name: "云手机直播配置",
    //     path: '/phone/live'
    // },
]
},
{
    index: '/line',
    key: 'livefast',
    name: '直播线路',
    icon: 'iconfont icon-jiasu',
    children: [
        {
            index: '/line/linelist',
            key: 'xlliebiao',
            name: '线路管理',
            path: '/line/linelist',
        },
        softRouterRoute,
        tiktokTool
    ]
},
{
    index: '/channelmanagement',
    key: 'qdgl',
    name: "渠道管理",
    icon: 'iconfont icon-yewuzu',
    children: [{
        index: '/channel/channelmain',
        key: 'qdhz',
        name: "所有账号客户汇总",
        path: '/channel/channelmain',
    },{
        index: '/channel/channelmainresource',
        key: 'qdzyhz',
        name: "所有账号客户资源汇总",
        path: '/channel/channelmainresource',
    },
    {
        index: '/channel/channelsub',
        key: 'qdsj',
        name: "本账号客户汇总",
        path: '/channel/channelsub',
    },
    {
        index: '/channel/channelresource',
        key: 'qdzy',
        name: "本账号客户资源汇总",
        path: '/channel/channelresource',
    },
    {
        index: '/channel/channelrebates',
        key: 'qdzy',
        name: "返利数据汇总",
        path: '/channel/channelrebates',
    }

    ]
},
{
    index: '/personalcenter',
    key: 'qyzx',
    name: "个人中心",
    icon: 'iconfont icon-gerenzhongxin',
    children: [{
        index: '/personalcenter/basicinformation',
        key: 'jbxx',
        name: "基本信息",
        path: '/personalcenter/basicinformation',
    }
    ]
}]

const child_phone_nomatrix_list = [
    {
    index: '/phone',
    key: 'yunshouji',
    name: '云手机',
    icon: 'iconfont icon-shouji',
    children: [{
        index: '/phone/phonelist',
        key: 'ysjliebiao',
        name: '通用云手机列表',
        path: '/phone/phonelist',
    // },{
    //     index: '/phone/matrixphonelist',
    //     key: 'jzysjliebiao',
    //     name: '矩阵云手机列表',
    //     path: '/phone/matrixphonelist',
    },  {
        index: '/phone/phonecontrol',
        key: 'ysjqk',
        name: '云手机操作',
        path: '/phone/phonecontrol'
    },
    //  {
    //     index: '/phone/live',
    //     key: 'ysjzb',
    //     name: "云手机直播配置",
    //     path: '/phone/live'
    // },
]
},
{
    index: '/line',
    key: 'livefast',
    name: '直播线路',
    icon: 'iconfont icon-jiasu',
    children: [
        {
            index: '/line/linelist',
            key: 'xlliebiao',
            name: '线路管理',
            path: '/line/linelist',
        },
        softRouterRoute,
        tiktokTool
    ]
},
{
    index: '/channelmanagement',
    key: 'qdgl',
    name: "渠道管理",
    icon: 'iconfont icon-yewuzu',
    children: [{
        index: '/channel/channelmain',
        key: 'qdhz',
        name: "所有账号客户汇总",
        path: '/channel/channelmain',
    },{
        index: '/channel/channelmainresource',
        key: 'qdzyhz',
        name: "所有账号客户资源汇总",
        path: '/channel/channelmainresource',
    },
    {
        index: '/channel/channelsub',
        key: 'qdsj',
        name: "本账号客户汇总",
        path: '/channel/channelsub',
    },
    {
        index: '/channel/channelresource',
        key: 'qdzy',
        name: "本账号客户资源汇总",
        path: '/channel/channelresource',
    },
    {
        index: '/channel/channelrebates',
        key: 'qdzy',
        name: "返利数据汇总",
        path: '/channel/channelrebates',
    }

    ]
},
{
    index: '/personalcenter',
    key: 'qyzx',
    name: "个人中心",
    icon: 'iconfont icon-gerenzhongxin',
    children: [{
        index: '/personalcenter/basicinformation',
        key: 'jbxx',
        name: "基本信息",
        path: '/personalcenter/basicinformation',
    }
    ]
}]


const child_nochannel_phone_list = [{
    index: '/phone',
    key: 'yunshouji',
    name: '云手机',
    icon: 'iconfont icon-shouji',
    children: [{
        index: '/phone/phonelist',
        key: 'ysjliebiao',
        name: '通用云手机列表',
        path: '/phone/phonelist',
    },{
        index: '/phone/matrixphonelist',
        key: 'jzysjliebiao',
        name: '矩阵云手机列表',
        path: '/phone/matrixphonelist',
    }, {
        index: '/phone/phonecontrol',
        key: 'ysjqk',
        name: '云手机操作',
        path: '/phone/phonecontrol'
    },
    //  {
    //     index: '/phone/live',
    //     key: 'ysjzb',
    //     name: "云手机直播配置",
    //     path: '/phone/live'
    // },
]
},
{
    index: '/line',
    key: 'livefast',
    name: '直播线路',
    icon: 'iconfont icon-jiasu',
    children: [
        {
            index: '/line/linelist',
            key: 'xlliebiao',
            name: '线路管理',
            path: '/line/linelist',
        },
        softRouterRoute,
        tiktokTool
    ]
},
{
    index: '/personalcenter',
    key: 'qyzx',
    name: "个人中心",
    icon: 'iconfont icon-gerenzhongxin',
    children: [{
        index: '/personalcenter/basicinformation',
        key: 'jbxx',
        name: "基本信息",
        path: '/personalcenter/basicinformation',
    }
    ]
},]

const child_nochannel_phone_nomatrix_list = [{
    index: '/phone',
    key: 'yunshouji',
    name: '云手机',
    icon: 'iconfont icon-shouji',
    children: [{
        index: '/phone/phonelist',
        key: 'ysjliebiao',
        name: '通用云手机列表',
        path: '/phone/phonelist',
    // },{
    //     index: '/phone/matrixphonelist',
    //     key: 'jzysjliebiao',
    //     name: '矩阵云手机列表',
    //     path: '/phone/matrixphonelist',
    }, {
        index: '/phone/phonecontrol',
        key: 'ysjqk',
        name: '云手机操作',
        path: '/phone/phonecontrol'
    },
    //  {
    //     index: '/phone/live',
    //     key: 'ysjzb',
    //     name: "云手机直播配置",
    //     path: '/phone/live'
    // },
]
},
{
    index: '/line',
    key: 'livefast',
    name: '直播线路',
    icon: 'iconfont icon-jiasu',
    children: [
        {
            index: '/line/linelist',
            key: 'xlliebiao',
            name: '线路管理',
            path: '/line/linelist',
        },
        softRouterRoute,
        tiktokTool
    ]
},
{
    index: '/personalcenter',
    key: 'qyzx',
    name: "个人中心",
    icon: 'iconfont icon-gerenzhongxin',
    children: [{
        index: '/personalcenter/basicinformation',
        key: 'jbxx',
        name: "基本信息",
        path: '/personalcenter/basicinformation',
    }
    ]
},]
export {
    childlist,
    mainlist,
    child_nochannel_list,
    main_nochannel_list,
    main_usertype_list,
    main_nochannel_usertype_list,

    child_phone_list,
    main_phone_list,
    child_nochannel_phone_list,
    main_nochannel_phone_list,
    main_usertype_phone_list,
    main_nochannel_usertype_phone_list,

    main_phone_nomatrix_list,
    main_nochannel_phone_nomatrix_list,
    main_usertype_phone_nomatrix_list,
    main_nochannel_usertype_phone_nomatrix_list,
    child_phone_nomatrix_list,
    child_nochannel_phone_nomatrix_list,

}
