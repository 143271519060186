import request from "@/utils/request"
import requestPhone from "@/utils/requestPhone"


//子账号列表
export function listsub(data) {
    return request({
        url: '/user/list_sub',
        method: 'post',
        data
    })
}

//创建子账号
export function createsub(data) {
    return request({
        url: '/user/create_sub',
        method: 'post',
        data
    })
}

//修改子账号昵称
export function modifysub(data) {
    return request({
        url: '/user/modify_sub',
        method: 'post',
        data
    })
}

//删除子账号
export function deletesub(data) {
    return request({
        url: '/user/delete_sub',
        method: 'post',
        data
    })
}

//重置子账号密码
export function resetpassword(data) {
    return request({
        url: '/user/sub_reset_password',
        method: 'post',
        data
    })
}


//子账号登录
export function sublogin(data) {
    return request({
        url: '/user/sub_login',
        method: 'post',
        data
    })
}

//分发云手机给子账号
export function distribute(data) {
    return requestPhone({
        url: '/phone/distribute',
        method: 'post',
        data
    })
}

//回收云手机到主账号
export function regain(data) {
    return requestPhone({
        url: '/phone/regain',
        method: 'post',
        data
    })
}

//分发矩阵云手机给子账号
export function distributematrix(data) {
    return requestPhone({
        url: '/phone/distribute_matrix',
        method: 'post',
        data
    })
}

//回收矩阵云手机到主账号
export function regainmatrix(data) {
    return requestPhone({
        url: '/phone/regain_matrix',
        method: 'post',
        data
    })
}


//可分发云手机列表
export function listcontrollable(data) {
    return requestPhone({
        url: '/phone/list_controllable',
        method: 'post',
        data
    })
}

