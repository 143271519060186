import request from "@/utils/request"

export function certification(data) {
    return request({
        url: "/user/submit_certification",
        method: "post",
        data,
        headers:{
            'Content-Type':'multipart/form-data'
        }
    })
}

export function businessLicenseOcr(data) {
    return request({
        url: "/user/business_license_ocr",
        method: "post",
        data,
        headers:{
            'Content-Type':'multipart/form-data'
        }
    })
}