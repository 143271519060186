import request from "@/utils/request"
import requestPhone from "@/utils/requestPhone"


export function getipregion(data) {
    return requestPhone({
        url: '/phone/ip_region_list',
        method: 'post',
        data
    })
}

export function getproduct(data) {
    return requestPhone({
        url: '/phone/product_list',
        method: 'post',
        data
    })
}

export function getregion(data) {
    return request({
        url: '/region/list',
        method: 'post',
        data
    })
}

export function createorder(data) {
    return request({
        url: '/order/create',
        method: 'post',
        data
    })
}

export function createrecharge(data) {
    return request({
        url: '/order/recharge',
        method: 'post',
        data
    })
}

export function getpayparams(data) {
    return request({
        url: '/order/pay_parameter',
        method: 'post',
        data
    })
}

export function payconfirm(data) {
    return request({
        url: '/order/pay_confirm',
        method: 'post',
        data
    })
}

export function getorders(data) {
    return request({
        url: '/order/list',
        method: 'post',
        data
    })
}

export function reneworder(data) {
    return request({
        url: '/order/renew',
        method: 'post',
        data
    })
}

export function getprice(data) {
    return request({
        url: '/order/phone_price',
        method: 'post',
        data
    })
}

export function extrachargetype(data) {
    return request({
        url: '/order/extraChargeType',
        method: 'post',
        data
    })
}

export function avaliableUpgrade(data) {
    return request({
        url: '/order/avaliabe_upgrade',
        method: 'post',
        data
    })
}

export function productUpgrade(data) {
    return request({
        url: '/order/product_upgrade',
        method: 'post',
        data
    })
}

export function phoneCreateOrder(data) {
    return requestPhone({
        url: '/order/create',
        method: 'post',
        data
    })
}

export function phoneCreateRecharge(data) {
    return requestPhone({
        url: '/order/recharge',
        method: 'post',
        data
    })
}

export function phoneGetPayParams(data) {
    return requestPhone({
        url: '/order/pay_parameter',
        method: 'post',
        data
    })
}

export function phonePayConfirm(data) {
    return requestPhone({
        url: '/order/pay_confirm',
        method: 'post',
        data
    })
}

export function phoneGetOrders(data) {
    return requestPhone({
        url: '/order/list',
        method: 'post',
        data
    })
}

export function phoneRenewOrder(data) {
    return requestPhone({
        url: '/order/renew',
        method: 'post',
        data
    })
}

export function phoneGetPrice(data) {
    return requestPhone({
        url: '/order/phone_price',
        method: 'post',
        data
    })
}

export function phoneExtraChargeType(data) {
    return requestPhone({
        url: '/order/extraChargeType',
        method: 'post',
        data
    })
}
