import {
    getuphonefiletoken,
    describeUFilesList,
    deleteuphonestoragefile,
    getfilesurl,
    finishupload,
    uploadtoken
} from "@/api/storage"
const state = {}
const mutations = {}
const actions = {
    getfiletoken({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getuphonefiletoken({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getufilelist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            describeUFilesList({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    deletestoragefile({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            deleteuphonestoragefile({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((res)=>{
                resolve(res)
            })
        })
    },
    getstorageurl({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getfilesurl({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    finishuploadfile({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            finishupload({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    },
    filetoken({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            uploadtoken({
                ...data
            }).then((res) => {
                resolve(res)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}