<template>
  <div>
    <div v-if="!item.children">
      <router-link :to="item.path">
        <el-menu-item :index="item.index">
          <i v-if="item.icon" :class="item.icon"></i>
          <img v-if="item.imgurl" :src="item.imgurl" class="imgicon" />
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </router-link>
    </div>
    <div v-else>
      <el-submenu :index="item.index">
        <template slot="title">
          <i v-if="item.icon" :class="item.icon"></i>
          <img
            v-if="item.imgurl"
            :src="item.imgurl"
            class="imgicon"
          />
          <span slot="title">{{ item.name }}</span>
        </template>
        <side-item v-for="item in item.children" :key="item.index" :item="item">
        </side-item>
      </el-submenu>
    </div>
  </div>
</template>
<script>
// import SideItem from '../SideBar/SideItem.vue';
export default {
  name: "side-item",
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.imgicon {
  width: 25px;
  height: 25px;
  margin-left: 7px;
}
</style>