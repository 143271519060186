<template>
  <el-badge :hidden="hideDot" value="待认证">
    <el-popover popper-class="gerenpop" trigger="click" ref="popoverRef" @show="showPopver">
      <div class="geren-main">
        <div class="userinfo">
          <div class="username">{{ username }}</div>
          <div class="userid">昵称：{{ nickname }}</div>
          <div class="subaccount-line">
            <div class="issub">
              {{ subaccount == true ? "子账号" : "主账号" }}
            </div>
            <div class="certification" v-if="certStatus">已实名认证</div>
            <div
              class="gocertification"
              v-else-if="!subaccount"
              @click="verifyName"
            >
              立即实名认证
            </div>
          </div>
        </div>
        <div class="list">
          <div
            class="list-item pointer"
            :class="{ center: subaccount }"
            @click="skipPersonalCenter"
          >
            {{ certStatus ? "企业中心" : "个人中心" }}
          </div>
          <div class="list-item" v-if="!subaccount">
            <div class="balance">
              <div class="balance-text">账户余额：{{ balance.toFixed(2) }}元</div>
              <div class="recharge" @click="recharge">充值</div>
            </div>
          </div>
        </div>
        <div class="button-single" @click="outlogin">退出登录</div>
      </div>
      <div slot="reference" class="geren">
        <i class="iconfont icon-gerenzhongxin"></i>
      </div>
    </el-popover>
  </el-badge>
</template>
<script>
import { getCookie} from "@/utils/auth";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      certStatus: false,
      subaccount: false,
      username: "",
      userId: "",
      visible: false,
      balance: 0,
      nickname: "",
    };
  },
  computed: {
    ...mapGetters(["subAccount"]),
    hideDot() {
      return this.certStatus || this.subAccount
    }
  },
  methods: {
    outlogin() {
      this.$refs.popoverRef.doClose();
      if (getCookie("subaccount")) {
        this.$store.dispatch("sub/outlogin").then(() => {
          this.$router.push("/login");
        });
      } else {
        this.$store.dispatch("user/outlogin").then(() => {
          this.$router.push("/login");
        });
      }
    },
    skipOrderCenter() {
      this.$refs.popoverRef.doClose();
      this.$router.push({ path: "/ordermanagement/index" });
    },
    skipPersonalCenter() {
      // if (this.subAccount) {
      //   return
      // }
      this.$refs.popoverRef.doClose();
      this.$router.push({ path: "/user/personalcenter" });
    },
    verifyName() {
      if (this.certStatus == false) {
        this.$router.push("/user/enterprise");
      }
      this.$refs.popoverRef.doClose();
    },
    recharge() {
      this.$refs.popoverRef.doClose();
      this.$router.push({ path: "/financialcenter/recharge" });
    },
    showPopver(){
      this.load();
    },
    load(){
      this.$store.dispatch("user/getuserinfo").then((res) => {
      if (res.RetCode == 0) {
        this.userId = res.UserId;
        if (!res.SubAccount) {
          this.username = res.PhoneNumber || res.Username;
        } else {
          this.username = res.Username || res.NickName;
        }
        this.certStatus = res.Authenticated;
        this.subaccount = res.SubAccount;
        this.nickname = res.NickName || res.PhoneNumber || res.Username;
      }
    });
    this.$store.dispatch("user/getuserwallet").then((res) => {
      if (res.RetCode == 0) {
        this.balance = res.Balance / 100;
      }
    });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style lang="scss">
.el-popover.gerenpop {
  padding: 0;
}
.geren {
  color: #ffffff;
  cursor: pointer;
  .icon-gerenzhongxin {
    font-size: 25px;
    font-weight: 700;
  }
}

.geren-main {
  width: 260px;

  .userinfo {
    padding: 16px 22px;
    box-sizing: border-box;
    // background-color: rgb(247, 248, 250);

    .username {
      font-size: 18px;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .userid {
      font-size: 13px;
      color: #999999;
      margin-top: 4px;
    }

    .subaccount-line {
      display: flex;
      font-size: 13px;
      margin-top: 6px;
      align-items: flex-end;
    }

    .issub {
      padding: 1px 8px;
      border-radius: 10px;
      border: 1px solid rgb(88, 151, 242);
    }

    .certification {
      margin-left: 12px;
      padding: 1px 8px;
      border-radius: 10px;
      border: 1px solid rgb(88, 151, 242);
    }

    .gocertification {
      margin-left: 12px;
      font-size: 15;
      text-decoration: underline;
      color: #ff6a00;
      cursor: pointer;
    }
  }

  .list {
    border-top: 1px solid #e1e1e1;
    padding: 0 22px;

    .list-item {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #efefef;
    }

    .list-item:last-child {
      border: none;
    }

    .list-item.pointer {
      cursor: pointer;
    }

    .list-item.center {
      text-align: center;
    }

    .balance {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .balance-text {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .recharge {
        padding: 0px 8px;
        border-radius: 4px;
        height: 24px;
        line-height: 24px;
        color: #fff;
        background: #409eff;
        cursor: pointer;
      }
    }
  }

  .button-single {
    // margin-top: 4px;
    border-top: 1px solid #e1e1e1;
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
  }
}

// .geren-main {
//   width: 200px;
//   height: 220px;
//   .usercount {
//     font-size: 16px;
//     font-weight: 600;
//     margin-left: 10px;
//     //color: transparent;
//     background-image: rgba(88, 151, 242, 1);
//     -webkit-background-clip: text;
//     .icon-gerenzhongxin {
//       font-size: 50px;
//       margin-left: 28px;
//     }
//   }
//   .detail-geren {
//     width: 100px;
//     height: 60px;
//     margin-top: 10px;
//     margin-left: 10px;
//     border-radius: 5px;
//     background: rgba(88, 151, 242, 1);
//     color: #fff;
//     .detail-text {
//       text-align: center;
//       font-weight: 600;
//       height: 30px;
//       line-height: 30px;
//     }
//   }
//   .settingbtn {
//     background: rgba(88, 151, 242, 1);
//     border-radius: 5px;
//     color: #fff;
//     margin-left: 10px;
//     margin-top: 30px;
//     width: 85px;
//   }
//   .outloginbtn {
//     background: rgba(88, 151, 242, 1);
//     border-radius: 5px;
//     color: #fff;
//     margin-left: 10px;
//     margin-top: 20px;
//     width: 85px;
//   }
// }
</style>