import {
    grouplist,
    phonelist,
    creategroup,
    deletegroup,
    modifygroup,
    changegroup,
    changegroupmatrix,
    setname,
    setmatrixname,
    reboot,
    setautorenew,
    setautorenewmatrix,
    phoneregionstock,
    poweron,
    poweroff,
    matrixworkgroupid,
    matrixgrouplist,
    usermatrixgrouplist,
    getphoneidsfororderid,
} from "@/api/group"

const state = {
    rtclist: [],
    phoneList: [],
    liveList: [],
    obsrtmpurl: '',
    rtmpurl: "",
    unmannedlivelist: [],
    timeout: 3000
}
const mutations = {
    setPhoneList(state, payload) {
        let statephonelist = []
        let includelist = []
        state.phoneList.forEach((item) => {
            statephonelist.push(item.Id)
        })
        if (payload.phoneList.length) {
            payload.phoneList.forEach((item) => {
                if (!statephonelist.includes(item.Id)) {
                    includelist.push(item)
                }else{
                    includelist.push(item)
                }
            })
            state.phoneList = includelist
        } else {
            state.phoneList = []
        }
        console.log(payload.phoneList)
    },
    setRtcList(state, payload) {
        state.rtclist.push(payload)
    },
    setTime(state, payload) {
        state.timeout = payload
    },
    clearRtc(state, payload) {
        state.rtclist.splice(payload, 1)
    },
    setobsrtmpurl(state, payload) {
        state.obsrtmpurl = payload.obsrtmpurl
    },
    //真人直播推流地址
    setrtpmurl(state, payload) {
        state.rtmpurl = payload.rtmpurl
    },
    //真人直播矩阵
    setLivelist(state, payload) {
        var newlivelist = payload.phonelist
        var rtclist = state.rtclist
        var samelist = []
        if (state.rtclist.length) {
            newlivelist.forEach((item, index) => {
                rtclist.forEach((eitem, eindex) => {
                    if (item.phoneId == eitem.phoneId) {
                        samelist.push(item)
                    }
                })
            })
            if (samelist.length) {
                sendlivemessage(state.rtmpurl, samelist)
            }
            state.liveList = newlivelist
        } else {
            state.liveList = newlivelist
        }
        console.log(samelist)
        console.log(state.liveList)
    },
    //清除真人直播矩阵
    clearlivelist(state, payload) {
        var newlivelist = payload.phonelist
        var rtclist = state.rtclist
        var samelist = []
        if (state.rtclist.length) {
            newlivelist.forEach((item, index) => {
                rtclist.forEach((eitem, eindex) => {
                    if (item.phoneId == eitem.phoneId) {
                        samelist.push(item)
                    }
                })
            })
            if (samelist.length) {
                stoplivemessage(samelist)
            }
        }
        state.liveList = []
        console.log(samelist)
        console.log(state.liveList)
    },
    //无人直播矩阵
    setunmannedlist(state, payload) {
        // console.log(payload)
        var unmannedlivelist = state.unmannedlivelist
        var newlivelist = payload.livelist
        var rtclist = state.rtclist
        var samelist = []
        if (state.rtclist.length) {
            newlivelist.forEach((item, index) => {
                rtclist.forEach((eitem, eindex) => {
                    if (item.phoneId == eitem.phoneId) {
                        samelist.push(item)
                    }
                })
            })
            if (samelist.length) {
                sendlivemessage(payload.fileurl, samelist)
            }
        }
        if (unmannedlivelist.length) {
            let phonelist = unmannedlivelist.map((item) => {
                return item.phoneId
            })
            let arr = [
                ...unmannedlivelist,
                ...newlivelist
            ]
            var list = unmannedlivelist.filter((item, index) => {
                newlivelist.forEach((nitem, nindex) => {
                    if (item.phoneId == nitem.phoneId) {
                        item.phoneId = nitem.phoneId
                        item.fileurl = nitem.fileurl
                    }
                })
                return true
            })
            var newlist = arr.filter((item, index) => {
                return !phonelist.includes(item.phoneId)
            })
            // console.log(list)
            // console.log(newlist)
            state.unmannedlivelist = [
                ...list,
                ...newlist,
            ]
            console.log(state.unmannedlivelist)
        } else {
            state.unmannedlivelist = newlivelist
            console.log(state.unmannedlivelist)
        }
    },
    //清除无人直播矩阵
    clearunmannedlist(state, payload) {
        var unmannedlivelist = state.unmannedlivelist
        var newlivelist = payload.livelist
        var samelist = []
        // console.log(unmannedlivelist)
        // console.log(newlivelist)
        if (unmannedlivelist.length) {
            let list = unmannedlivelist.filter((item, index) => {
                var flag = false
                newlivelist.forEach((nitem, nindex) => {
                    if (item.fileurl == nitem.fileurl && item.phoneId == nitem.phoneId) {
                        flag = true
                    }
                })
                if (flag) {
                    if (state.rtclist.length) {
                        samelist.push(item)
                    }
                    return false
                } else {
                    return true
                }
            })
            state.unmannedlivelist = list
            console.log(list)
        }
        // console.log(samelist)
        if (samelist.length) {
            stoplivemessage(samelist)
        }
    }
}
const actions = {
    getgrouplist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            grouplist({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    createnewgroup({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            creategroup({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                rejecr(err)
            })
        })
    },
    deletegrouplist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            deletegroup({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err => {
                reject(err)
            }))
        })
    },
    editegrouplist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            modifygroup({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getphonelist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            phonelist({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            });
        })
    },
    changephonegroup({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            changegroup({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    changephonegroupmatrix({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            changegroupmatrix({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    changephonename({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setname({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    changematrixname({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setmatrixname({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },


    phonereboot({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            reboot({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },

    setphoneautorenew({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setautorenew({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },   
    setphoneautorenewmatrix({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setautorenewmatrix({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }, 
    phoneregionstocklist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            phoneregionstock({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },   
    poweronphone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            poweron({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }, 
    poweroffphone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            poweroff({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }, 
    getmatrixworkgroupid({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            matrixworkgroupid({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }, 
    getmatrixgrouplist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            matrixgrouplist({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }, 
    getusermatrixgrouplist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            usermatrixgrouplist({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }, 
    getgetphoneidsfororderid({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getphoneidsfororderid({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }, 


}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}