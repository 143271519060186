import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user"
import getters from "./getters"
import pay from "./modules/pay"
import group from "./modules/group"
import control from "./modules/control"
import sub from "./modules/sub"
import line from "./modules/line"
import us3 from "./modules/us3"
import personal from "./modules/personal"
import storage from "./modules/storage"
import routers from './modules/routers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters,
  mutations: {},
  actions: {},
  modules: {
    user,
    pay,
    group,
    control,
    sub,
    line,
    us3,
    personal,
    storage,
    routers
  }
})
