<template>
  <div id="app">
    <router-view />
    <announcement/>
  </div>
</template>
<script>
import Announcement from "@/views/Announcement.vue";

export default {
  data() {
    return {};
  },
  components: {Announcement},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
</style>
