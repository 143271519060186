/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isPhonenumber(path) {
  return  /^1[3-9]\d{9}$/.test(path)
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
 export function isSubNumber(path) {
  return  /^[a-zA-Z0-9_@]{2,}$/.test(path)
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @description 替换字符串内指定地位的值
 * @param {Number|String} startPosition - 开始地位
 * @param {Number|String} endPosition - 完结地位-该地位文本不做替换操作
 * @param {String} originStr - 原始文本
 * @param {String} replaceStr - 替换文本
 * @return {String}
 */
 export function replaceStr({
  startPosition = 0,
  endPosition = 0,
  originStr = "",
  replaceStr = "",
}) {
  if (originStr === "") {
    throw new TypeError("请传入有效文本！");
  }
  if (typeof startPosition !== "number") {
    startPosition = Number(startPosition);
  }
  if (typeof endPosition !== "number") {
    endPosition = Number(endPosition);
  }
  if (typeof originStr !== "string") {
    originStr += "";
  }
  if (typeof replaceStr !== "string") {
    replaceStr += "";
  }
  let finalStr = "";
  const repeatStrLength = endPosition - startPosition;
  const middleStr = "".padEnd(repeatStrLength, replaceStr);
  finalStr = `${originStr.slice(0, startPosition)}${middleStr}${originStr.slice(
    endPosition
  )}`;
  return finalStr;
}