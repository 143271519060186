const getters = {
    token: state => {
        return state.user.token
    },
    subtoken: state => {
        return state.sub.token
    },
    subAccount: state => {
        return state.user.subAccount
    },
    userId: state => {
        return state.user.userId
    },
    channelType: state => {
        return state.user.channelType
    },
    parentId: state => {
        return state.user.parentId
    },
    filterlist: state => {
        return state.sub.filterlist
    },
    menulist: state => {
        return state.user.menulist
    },
    gender: state => {
        return state.user.gender
    },
    nickname: state => {
        return state.user.nickname
    },
    phonenumber: state => {
        return state.user.phonenumber
    },
    wechat: state => {
        return state.user.wechat
    },
    qq: state => {
        return state.user.qq
    },
    rtclist: state => {
        return state.group.rtclist
    },
    phoneList: state => {
        return state.group.phoneList
    },
    liveList: state => {
        return state.group.liveList
    },
    obsrtmpurl: state => {
        return state.group.obsrtmpurl
    },
    rtmpurl: state => {
        return state.group.rtmpurl
    },
    unmannedlivelist: state => {
        return state.group.unmannedlivelist
    }

}

export default getters
