import request from "@/utils/request"
import requestPhone from "@/utils/requestPhone"

export function applyauth(data) {
    return requestPhone({
        url: '/us3/applyAuth',
        method: 'post',
        data
    })
}

export function applyurlauth(data) {
    return requestPhone({
        url: "/us3/applyPrivateUrlAuth",
        method: 'post',
        data
    })
}