import {
    listsub,
    createsub,
    deletesub,
    modifysub,
    resetpassword,
    distribute,
    distributematrix,
    regain,
    regainmatrix,
    sublogin,
    listcontrollable,
} from "@/api/subaccount"
import {
    getToken,
    setToken,
    removeToken,
    setCookie,
    removeCookie,
    removePhoneNumber
} from '@/utils/auth'
const state = {
    token: getToken(),
    filterlist: []
}
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    setfilterllist: (state, data) => {
        var filter = data.map((item, index) => {
            return {
                text: item.Username,
                value: item.Username 
            }
        })
        // console.log(filter)
        state.filterlist = filter
    }
}
const actions = {
    getsublist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            listsub({
                ...data
            }).then((res) => {
                if (res.RetCode == 0) {
                    commit("setfilterllist",res.UserInfos)
                }
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    createaccount({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            createsub({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    deleteaccount({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            deletesub({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    editeaccount({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            modifysub({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    resetsubpass({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            resetpassword({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    distributephone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            distribute({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    distributematrixphone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            distributematrix({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    regainphone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            regain({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    regainmatrixphone({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            regainmatrix({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    login({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            sublogin({
                ...data
            }).then((res) => {
                if (res.RetCode == 0) {
                    setToken(res.AccessToken)
                    commit("SET_TOKEN", res.AccessToken)
                }
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    outlogin({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            commit("SET_TOKEN", '')
            removeToken()
            removeCookie('subaccount')
            removeCookie('authenticated')
            removeCookie('username')
            removePhoneNumber()
            resolve()
        })
    },
    getphonelist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            listcontrollable({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }

}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}