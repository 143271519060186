import request from '@/utils/request'

export function createRouterOrder(data) {
    return request({
        url: '/order/create_router',
        method: 'post',
        data: {
            ProductId: 'router-' + data.routerType,
            BuyCount: data.buyCount,
            Name: data.name,
            Ext: {
                Address: data.receiver.address,
                Receiver: data.receiver.name,
                Phone: data.receiver.phone
            }
        }
    })
}

export function list() {
    return request({
        url: '/resource/v1/path/routers',
        method: 'get'
    })
}

export function modifyWiFi({
  RouterId,
  domestic: { SSID: ssidInternal, Key: keyInternal },
  fast: { SSID: ssidLine, Key: keyLine },
}) {
  return request({
    url: `/resource/v1/path/routers/${RouterId}/wifi`,
    method: "post",
    data: {
      routerId: RouterId,
      ssidInternal,
      keyInternal,
      ssidLine,
      keyLine,
    },
  });
}

export function bind({RouterId, LineId}) {
    return request({
        url: `/resource/v1/path/routers/${RouterId}/bind`,
        method: 'post',
        data: {
            routerId: RouterId,
            lineId: LineId
        }
    })
}

export function unbind({RouterId}) {
    return request({
        url: `/resource/v1/path/routers/${RouterId}/unbind`,
        method: 'post'
    })
}

export function reconnect({RouterId}) {
    return request({
        url: `/resource/v1/path/routers/${RouterId}/reconnect`,
        method: 'post'
    })
}

export function routerUpdate({routerId, name}) {
    return request({
        url: `/resource/v1/path/routers/${routerId}/update`,
        method: 'post',
        data: {
            name
        }
    })
}

export function routerOnline(routerId) {
    return request({
        url: `/resource/v1/path/routers/${routerId}/online`,
        method: 'get'
    })
}


export function routerPrice({productId, buyCount}) {
    return request({
        url: '/order/router_price',
        method: 'post',
        data: {
            ProductId: 'router-' + productId,
            BuyCount: buyCount
        }
    })
}

// 路由器物流查询
export function deliveryQuery() {
    return request({
        url: "/resource/v1/path/delivery",
        method: 'get'
    })
}

// 路由器签收
export function deliverySign(routerId) {
    return request({
        url: "/resource/v1/path/deliverySign/" + routerId,
        method: 'post'
    })
}