import {
    getregion,
    getipregion,
    getproduct,
    createorder,
    getpayparams,
    payconfirm,
    reneworder,
    getorders,
    getprice,
    createrecharge,
    extrachargetype,
    avaliableUpgrade,
    productUpgrade,
    phoneCreateOrder,
    phoneCreateRecharge,
    phoneGetPayParams,
    phonePayConfirm,
    phoneRenewOrder,
    phoneGetOrders, phoneGetPrice, phoneExtraChargeType,
} from "@/api/pay"
const state = {}
const mutations = {}
const actions = {
    getregionlist({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            getregion({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getipregionlist({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            getipregion({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getproductlist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getproduct({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    createordernumber({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            phoneCreateOrder({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    createrechargeorder({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            phoneCreateRecharge({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getpaymentparams({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            phoneGetPayParams({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getpayconfirm({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            phonePayConfirm({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    createreneworder({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            phoneRenewOrder({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getorderlist({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            phoneGetOrders({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getphoneprice({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            phoneGetPrice({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getextrachargetype({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            phoneExtraChargeType({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getAvaliableUpgrade({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            avaliableUpgrade({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    productUpgrade({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            productUpgrade({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}