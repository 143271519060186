import {
    getregion,
    getlist,
    createorder,
    getpayparams,
    payconfirm,
    gettoken,
    getprice,
    resetpath,
    resettrialpath,
    changegroup,
    setname,
    setautorenew,
    distribute,
    regain,
    regionstock,
    setipmetrics,
    setpingmetrics,
    getconnetctdetail,
    getpingdetail,
    gettiktokregion,
    getTkStat,
    isshowprobation,

} from "@/api/line"
import {getorders, phoneGetOrders, phoneGetPrice, phoneRenewOrder, reneworder} from "@/api/pay";
const state = {}
const mutations = {}
const actions = {
    regionstocklist({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            regionstock({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getregionlist({
        commit
    }, data) {
        return new Promise((reslove, reject) => {
            getregion({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getlinelist({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getlist({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    createordernumber({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            createorder({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getpaymentparams({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getpayparams({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    createreneworder({
                         commit
                     }, data) {
        return new Promise((resolve, reject) => {
            reneworder({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getpayconfirm({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            payconfirm({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getorderlist({
                     commit
                 }, data) {
        return new Promise((reslove, reject) => {
            getorders({
                ...data
            }).then((res) => {
                reslove(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getpathtoken({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            gettoken({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getpathprice({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getprice({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    resetline({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            resetpath({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    resetTrailLine({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            resettrialpath({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    changeGroup({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            changegroup({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    changelinename({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setname({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    setlineautorenew({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setautorenew({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    setlineipmetrics({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setipmetrics({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getconnetctdetails({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getconnetctdetail({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    gettiktokregions({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            gettiktokregion({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getTkState({commit}, data) {
        return new Promise((resolve, reject) => {
            getTkStat({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getpingdetails({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            getpingdetail({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    setlinepingmetrics({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            setpingmetrics({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    distributeline({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            distribute({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    regainline({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            regain({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    isprobation({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            isshowprobation({
                ...data
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
