import Cookies from "js-cookie"

const TokenKey = 'Authorization'
const PNKey = 'PhoneNumber'
const RootDomain = ".wumitech.com"

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, {
        domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : RootDomain
    })
}

export function setPhoneNumber(phonenumber) {
    return Cookies.set(PNKey, phonenumber, {
        domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : RootDomain
    })
}

export function removeToken() {
    return Cookies.remove(TokenKey, {
        domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : RootDomain
    })
}

export function removePhoneNumber() {
    return Cookies.remove(PNKey, {
        domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : RootDomain
    })
}

export function getCookie(name) {
    return Cookies.get(name)
}

export function setCookie(name, value) {
    return Cookies.set(name, value)
}

export function removeCookie(name) {
    return Cookies.remove(name)
}

export function setsession(name, value) {
    return window.sessionStorage.setItem(name, value)
}

export function getsession(name) {
    return window.sessionStorage.getItem(name)
}

export function removesession(name) {
    return window.sessionStorage.removeItem(name)
}

export function clearsession() {
    return window.sessionStorage.clear()
}