import request from "@/utils/request"

export function getregion(data) {
    return request({
        url: '/resource/v1/path/ip_region',
        method: 'get',
        data
    })
}

export function getlist(data) {
    return request({
        url: '/resource/v1/path/lines',
        method: 'get',
        params: data,
    })
}
export function regionstock(data) {
    return request({
        url: '/resource/v1/path/regionStock',
        method: 'get',
        params: data,
    })
}

export function createorder(data) {
    return request({
        url: '/order/create_path',
        method: 'post',
        data
    })
}

export function getpayparams(data) {
    return request({
        url: '/order/pay_parameter',
        method: 'post',
        data
    })
}

export function payconfirm(data) {
    return request({
        url: '/order/pay_confirm',
        method: 'post',
        data
    })
}

export function gettoken(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/token`,
        method: 'get',
        data
    })
}

export function getprice(data) {
    return request({
        url: '/order/path_price',
        method: 'post',
        data
    })
}

export function resetpath(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/reset`,
        method: 'post',
        data
    })
}

export function resettrialpath(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/trialReset`,
        method: 'post',
        data
    })
}

export function changegroup(data) {
    return request({
        url: '/resource/v1/path/groups',
        method: 'put',
        data
    })
}

export function setname(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/name`,
        method: 'post',
        data
    })
}

export function setautorenew(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/autoRenew`,
        method: 'post',
        data
    })
}

export function setipmetrics(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/ipMetrics`,
        method: 'get',
        params: data,
    })
}

export function setpingmetrics(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/pingMetrics`,
        method: 'get',
        params: data,
    })
}

export function getconnetctdetail(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/connectDetail`,
        method: 'get',
        params: data,
    })
}

export function getpingdetail(data) {
    return request({
        url: `resource/v1/path/lines/${data.id}/pingDetail`,
        method: 'get',
        params: data,
    })
}

export function distribute(data) {
    return request({
        url: `resource/v1/path/distribute`,
        method: 'post',
        data
    })
}

export function regain(data) {
    return request({
        url: `resource/v1/path/regain`,
        method: 'post',
        data
    })
}

export function gettiktokregion(data) {
    return request({
        url: `resource/v1/path/getRegion`,
        method: 'get',
        params: data,
    })
}

export function getTkStat(data) {
    return request({
        url: `resource/v1/path/tkState`,
        method: 'get',
        params: data,
    })
}

export function isshowprobation(data) {
    return request({
        url: `resource/v1/path/${data.id}/trialPermission`,
        method: 'get',
        params: data,
    })
}