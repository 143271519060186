import {
    applyauth,
    applyurlauth
} from "@/api/us3.js"
const state = {}
const mutations = {}
const actions = {
    getauthtoken({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            applyauth({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    geturlauth({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            applyurlauth({
                ...data
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}